<template>
  <FlotoCrudContainer
    ref="list"
    as-table
    :scrollable="scrollable"
    :fetch-fn="fetchUserFn"
    :delete-fn="deleteUserFn"
    :columns="tableColumns"
    :selectable="selectable"
    v-bind="$attrs"
    @selection-change="$emit('selection-change', $event)"
  >
    <template v-slot:add-controls="{ refreshList }">
      <MRow v-if="searchable" :gutter="0" class="items-center">
        <MCol :size="12" class="flex items-center mb-4">
          <div class="flex-1">
            <FlotoSearchBar
              ref="searchBar"
              :allow-keywords="false"
              :allow-save-search="false"
              :persist-criteria="false"
              :module-name="$constants.USER"
              :exclude-search-params="type === 'technician' ? ['vip'] : []"
              @change="applyCriteria($event, refreshList)"
            />
          </div>
        </MCol>
      </MRow>
    </template>
    <template v-slot:form>
      <span />
    </template>
    <template v-slot:department="{ item }">
      <td>
        <FlotoDepartmentPicker
          :value="item.department"
          placeholder="---"
          disabled
          text-only
        />
      </td>
    </template>
    <template v-slot:location="{ item }">
      <td>
        <FlotoLocationPicker
          v-if="item.location"
          :value="item.location"
          placeholder="---"
          size="small"
          disabled
          text-only
        />
        <span v-else>---</span>
      </td>
    </template>
    <template v-slot:actions="{ item, remove }">
      <td
        :class="{
          'sticky-action': actionSticked.indexOf(item.id) >= 0,
          'hover-action-container': hoverableActions,
        }"
      >
        <slot
          name="action"
          :item="item"
          :stick-action="() => stickActionFor(item)"
          :unstick-action="() => unStickActionFor(item)"
        >
          <FlotoDeleteBtn
            :class="{ invisible: !item.canDelete }"
            :message="$t('confirm_remove_item', { item: $tc('user') })"
            @confirm="remove"
          />
        </slot>
      </td>
      <!-- <td align="right">
        <FlotoDeleteBtn
          :class="{ invisible: !item.canDelete }"
          :message="$t('confirm_remove_item', { item: $tc('user') })"
          @confirm="remove"
        />
      </td> -->
    </template>
    <template v-slot="slotData">
      <slot v-bind="slotData"></slot>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import Uniq from 'lodash/uniq'
import { getUsersApi, deleteUserApi } from '@modules/users/users-api'

export default {
  name: 'UserList',
  props: {
    fetchFn: { type: Function, default: undefined },
    deleteFn: { type: Function, default: undefined },
    type: { type: String, required: true },
    hasActions: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
    hiddenColumns: {
      type: Array,
      default() {
        return ['contactNo']
      },
    },
    hoverableActions: { type: Boolean, default: false },
    excludeItemsById: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      searchCriterias: {
        quals: [],
      },
      tableColumns: [
        { name: `${this.$t('name')}`, key: 'name' },
        { name: `${this.$tc('email')}`, key: 'email' },
        ...(this.hiddenColumns.indexOf('department') < 0
          ? [{ name: `${this.$tc('department')}`, key: 'department' }]
          : []),
        ...(this.hiddenColumns.indexOf('contactNo') < 0
          ? [{ name: `${this.$tc('contact_no')}`, key: 'contactNo' }]
          : []),
        ...(this.hiddenColumns.indexOf('location') < 0
          ? [{ name: `${this.$tc('location')}`, key: 'location' }]
          : []),
        ...(this.hasActions
          ? [
              {
                name: `${this.$tc('action')}`,
                key: 'actions',
                width: '120px',
                align: 'left',
              },
            ]
          : []),
      ],
      searchCriteria: [],
      actionSticked: [],
    }
  },
  methods: {
    applyCriteria(searchCriteria, refreshList) {
      this.searchCriterias = searchCriteria
      refreshList()
    },
    fetchUserFn(limit, offset) {
      const filter = {}
      if (this.excludeItemsById.length) {
        filter.excludedIds = this.excludeItemsById
      }
      if (this.fetchFn) {
        return this.fetchFn(this.type, this.searchCriterias, limit, offset)
      } else {
        return getUsersApi(
          this.type,
          filter,
          this.searchCriterias,
          limit,
          offset
        )
      }
    },
    deleteUserFn({ id }) {
      if (this.deleteFn) {
        return this.deleteFn(this.type, id)
      } else {
        return deleteUserApi(this.type, id)
      }
    },
    refresh() {
      this.$refs.list.refresh()
    },
    stickActionFor(item) {
      this.actionSticked = Uniq([...this.actionSticked, item.id])
    },
    unStickActionFor(item) {
      this.actionSticked = this.actionSticked.filter((id) => id !== item.id)
    },
  },
}
</script>
