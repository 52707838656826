<script>
import { flattenRecursive } from '@data/recursive'
import { buildRelationalQualificationStructure } from '@data/qualification'
import { getUsersApi } from '@modules/users/users-api'
import { CategoryComputed } from '@state/modules/category'
import { searchCategoriesApi } from '@modules/category/category-api'
import { StatusComputed } from '@state/modules/status'
import { PriorityComputed } from '@state/modules/priority'
import { ImpactComputed } from '@state/modules/impact'
import { UrgencyComputed } from '@state/modules/urgency'
import { TechnicianComputed } from '@state/modules/technician'
import { UserGroupsComputed } from '@state/modules/user-group'
import { getGroupsApi } from '@modules/user-groups/user-groups-api'
import {
  DepartmentComputed,
  getDepartmentsApi,
} from '@state/modules/department'
import { LocationComputed, searchLocationsApi } from '@state/modules/location'
import { TaskComputed } from '@state/modules/task'
import { SourceComputed } from '@state/modules/source'
import { SoftwareTypeComputed } from '@state/modules/software-type'
import { AssetTypeComputed } from '@state/modules/asset-type'
import { searchAssetTypesApi } from '@modules/asset-management/api/asset-type-api'
import {
  BusinessServiceComputed,
  BusinessServiceMethods,
} from '@state/modules/business-service'
import { RiskTypeComputed, RiskTypeMethods } from '@state/modules/risk-type'
import {
  ReleaseRiskTypeComputed,
  ReleaseRiskTypeMethods,
} from '@state/modules/release-risk-type'
import {
  ProjectRiskTypeComputed,
  ProjectRiskTypeMethods,
} from '@state/modules/project-risk-type'
import {
  ChangeTypeComputed,
  ChangeTypeMethods,
} from '@state/modules/change-type'
import {
  ReasonTypeComputed,
  ReasonTypeMethods,
} from '@state/modules/reason-type'
import {
  ReleaseReasonTypeComputed,
  ReleaseReasonTypeMethods,
} from '@state/modules/release-reason-type'
import {
  ReleaseTypeComputed,
  ReleaseTypeMethods,
} from '@state/modules/release-type'
import {
  ProjectTypeComputed,
  ProjectTypeMethods,
} from '@state/modules/project-type'

import { getProductCatalogsApi } from '@modules/asset-management/api/product-catalog-api'
import { getVendorCatalogsApi } from '@modules/asset-management/api/vendor-catalog-api'
import { getRemoteOfficesApi } from '@modules/patch-management/api/remote-office-api'
import { getTemplatesApi } from '@modules/templates/templates-api'
import { getDeploymentPoliciesApi } from '@modules/patch-management/api/deployment-policy-api'
import { getGlCodesApi } from '@modules/purchase-management/api/gl-code-api'
import { getCostCentersApi } from '@modules/purchase-management/api/cost-center-api'
import { getAssetGroupsApi } from '@modules/asset-management/api/asset-group-api'
import { getServiceCatalogsApi } from '@modules/service-catalog/service-catalog-api'
import { getScheduleSurveysApi } from '@modules/user-survey/user-survey-api'
import {
  ContractTypeComputed,
  ContractTypeMethods,
} from '@state/modules/contract-type'
import { getSoftwareLicenseTypesApi } from '@modules/asset-management/api/software-license-type-api'
import { getAssetTypeSystemName } from '@modules/asset/helpers/asset-type'
import { getCompaniesApi } from '@modules/organization/company-api'
import { searchSsoConfigApi } from '@modules/it-infrastructure/api/sso-config-api'
import { getSupportDataApi } from '@modules/workflow/workflow-api'
import { fieldValueOperators } from '@data/operator'
import { LicenseComputed } from '@/src/state/modules/license'

export default {
  name: 'SearchBarProvider',
  provide() {
    const searchBarContext = {
      name: 'searchBarData',
      // search bar selected value get list
      getRequesters: this.fetchRequesters,
      getProducts: this.fetchProducts,
      getVendors: this.fetchVendors,
      getRemoteOffices: this.fetchRemoteOffices,
      getDeploymentPolicy: this.fetchDeploymentPolicies,
      getTemplates: this.fetchTemplates,
      getCompanies: this.getCompanyOptions,
      getSsoConfigs: this.getSSOConfigOptions,
      // fetch archive options
      getArchivedOptions: this.fetchArchivedOptions,
      getSupportData: this.getSupportData,
      getSerciceCatalogs: this.getServiceCatalogOptions,
    }
    // add support data
    Object.defineProperty(searchBarContext, 'supportData', {
      enumerable: true,
      get: () => {
        return this.supportData
      },
    })
    Object.defineProperty(searchBarContext, 'requesterOptions', {
      enumerable: true,
      get: () => {
        return this.requesterOptions
      },
    })
    // set availabe asset types property on asset type picker in search bar
    Object.defineProperty(searchBarContext, 'availableAssetType', {
      enumerable: true,
      get: () => {
        if (this.moduleName === this.$constants.SNMP_PROPERTY_DEVICE) {
          return getAssetTypeSystemName.snmp_devices
        }
        if (this.moduleName === this.$constants.BASELINE) {
          return getAssetTypeSystemName.computer
        }
        return this.parentModuleName === this.$constants.ASSET
          ? getAssetTypeSystemName[this.moduleName]
          : undefined
      },
    })
    // add status
    Object.defineProperty(searchBarContext, `${this.parentModuleName}Status`, {
      enumerable: true,
      get: () => {
        if (this.parentModuleName === this.$constants.TASK) {
          return this[`${this.parentModuleName}Status`].filter(
            (s) => (s.systemName || '').toLowerCase() !== 'cancelled'
          )
        }
        return this[`${this.parentModuleName}Status`]
      },
    })
    // add priority
    Object.defineProperty(searchBarContext, 'priorityOptions', {
      enumerable: true,
      get: () => {
        return this.priorityOptions
      },
    })
    // add category
    Object.defineProperty(searchBarContext, `${this.moduleName}Categories`, {
      enumerable: true,
      get: () => {
        return this[`${this.moduleName}Categories`]
      },
    })
    Object.defineProperty(searchBarContext, `serviceCategories`, {
      enumerable: true,
      get: () => {
        return this.serviceCatalogCategories
      },
    })
    // add impact
    Object.defineProperty(searchBarContext, 'impactOptions', {
      enumerable: true,
      get: () => {
        return this.impactOptions
      },
    })
    // add urgency
    Object.defineProperty(searchBarContext, 'urgencyOptions', {
      enumerable: true,
      get: () => {
        return this.urgencyOptions
      },
    })
    // add technicians
    Object.defineProperty(searchBarContext, 'technicianOptions', {
      enumerable: true,
      get: () => {
        return this.technicianOptions
      },
    })
    // add technicians group
    Object.defineProperty(searchBarContext, 'technicianGroupsOptions', {
      enumerable: true,
      get: () => {
        return this.technicianGroupsOptions
      },
    })
    // add requesters group
    Object.defineProperty(searchBarContext, 'requesterGroupsOptions', {
      enumerable: true,
      get: () => {
        return this.requesterGroupsOptions
      },
    })
    // add service catalog
    Object.defineProperty(searchBarContext, 'serviceCatalogOptions', {
      enumerable: true,
      get: () => {
        return this.serviceCatalogOptions
      },
    })
    // add schedule survey
    Object.defineProperty(searchBarContext, 'scheduleSurveyOptions', {
      enumerable: true,
      get: () => {
        return this.scheduleSurveyOptions
      },
    })
    // add department
    Object.defineProperty(searchBarContext, 'departmentOptions', {
      enumerable: true,
      get: () => {
        return this.departmentOptions
      },
    })
    // add location
    Object.defineProperty(searchBarContext, 'locationOptions', {
      enumerable: true,
      get: () => {
        return this.locationOptions
      },
    })
    // add task type
    Object.defineProperty(searchBarContext, 'taskTypeOptions', {
      enumerable: true,
      get: () => {
        return this.taskTypeOptions
      },
    })
    // add source
    Object.defineProperty(searchBarContext, 'sourceOptions', {
      enumerable: true,
      get: () => {
        return this.sourceOptions
      },
    })
    // add asset type
    Object.defineProperty(searchBarContext, 'assetTypes', {
      enumerable: true,
      get: () => {
        return this.assetTypes
      },
    })
    // add business service
    Object.defineProperty(searchBarContext, 'businessServiceOptions', {
      enumerable: true,
      get: () => {
        return this.businessServiceOptions
      },
    })
    // add risk type
    Object.defineProperty(searchBarContext, 'riskTypeOptions', {
      enumerable: true,
      get: () => {
        return this.riskTypeOptions
      },
    })
    // add release risk type
    Object.defineProperty(searchBarContext, 'releaseReleaseRiskTypeOptions', {
      enumerable: true,
      get: () => {
        return this.releaseReleaseRiskTypeOptions
      },
    })
    // add change type
    Object.defineProperty(searchBarContext, 'changeTypeOptions', {
      enumerable: true,
      get: () => {
        return this.changeTypeOptions
      },
    })
    // add release type
    Object.defineProperty(searchBarContext, 'releaseTypeOptions', {
      enumerable: true,
      get: () => {
        return this.releaseTypeOptions
      },
    })
    // add reason type
    Object.defineProperty(searchBarContext, 'reasonTypeOptions', {
      enumerable: true,
      get: () => {
        return this.reasonTypeOptions
      },
    })
    // add release reason type
    Object.defineProperty(searchBarContext, 'releaseReasonTypeOptions', {
      enumerable: true,
      get: () => {
        return this.releaseReasonTypeOptions
      },
    })
    // add product
    Object.defineProperty(searchBarContext, 'productOptions', {
      enumerable: true,
      get: () => {
        return this.productOptions
      },
    })
    // add vendor
    Object.defineProperty(searchBarContext, 'vendorOptions', {
      enumerable: true,
      get: () => {
        return this.vendorOptions
      },
    })
    // add remoteOffice
    Object.defineProperty(searchBarContext, 'remoteOfficeOptions', {
      enumerable: true,
      get: () => {
        return this.remoteOfficeOptions
      },
    })
    // add template
    Object.defineProperty(searchBarContext, 'templateOptions', {
      enumerable: true,
      get: () => {
        return this.templateOptions
      },
    })
    // add deployment policy
    Object.defineProperty(searchBarContext, 'deploymentPolicyOptions', {
      enumerable: true,
      get: () => {
        return this.deploymentPolicyOptions
      },
    })
    // add software Type
    Object.defineProperty(searchBarContext, 'softwareTypeOptions', {
      enumerable: true,
      get: () => {
        return this.softwareTypeOptions
      },
    })
    // add gl-code
    Object.defineProperty(searchBarContext, 'glCodeOptions', {
      enumerable: true,
      get: () => {
        return this.glCodeOptions
      },
    })
    // add cost-center
    Object.defineProperty(searchBarContext, 'costCenterOptions', {
      enumerable: true,
      get: () => {
        return this.costCenterOptions
      },
    })
    // add asset group
    Object.defineProperty(searchBarContext, 'assetGroupOptions', {
      enumerable: true,
      get: () => {
        return this.assetGroupOptions
      },
    })
    // add asset group
    Object.defineProperty(searchBarContext, 'softwareLicenseTypeOptions', {
      enumerable: true,
      get: () => {
        return this.softwareLicenseTypeOptions
      },
    })
    // add contract type
    Object.defineProperty(searchBarContext, 'contractTypeOptions', {
      enumerable: true,
      get: () => {
        return this.contractTypeOptions
      },
    })
    // add project type
    Object.defineProperty(searchBarContext, 'projectTypeOptions', {
      enumerable: true,
      get: () => {
        return this.projectTypeOptions
      },
    })
    // add project risk type
    Object.defineProperty(searchBarContext, 'projectRiskTypeOptions', {
      enumerable: true,
      get: () => {
        return this.projectRiskTypeOptions
      },
    })
    // add company
    Object.defineProperty(searchBarContext, 'companyOptions', {
      enumerable: true,
      get: () => {
        return this.companyOptions
      },
    })
    // sso config
    Object.defineProperty(searchBarContext, 'ssoConfigOptions', {
      enumerable: true,
      get: () => {
        return this.ssoConfigOptions
      },
    })
    // archived options
    Object.defineProperty(searchBarContext, 'archivedOptionsMap', {
      enumerable: true,
      get: () => {
        return this.archivedOptionsMap
      },
    })
    return { searchBarContext }
  },
  props: {
    moduleName: { type: String, required: true },
    preventApi: { type: Boolean, default: false },
  },
  data() {
    return {
      supportData: [],
      requesterOptions: [],
      productOptions: [],
      vendorOptions: [],
      remoteOfficeOptions: [],
      templateOptions: [],
      deploymentPolicyOptions: [],
      serviceCatalogOptions: [],
      scheduleSurveyOptions: [],
      glCodeOptions: [],
      costCenterOptions: [],
      softwareLicenseTypeOptions: [],
      assetGroupOptions: [],
      archivedOptionsMap: {},
      companyOptions: [],
      ssoConfigOptions: [],
    }
  },
  computed: {
    ...StatusComputed,
    ...PriorityComputed,
    ...CategoryComputed,
    ...ImpactComputed,
    ...UrgencyComputed,
    ...TechnicianComputed,
    ...UserGroupsComputed,
    ...DepartmentComputed,
    ...LocationComputed,
    ...TaskComputed,
    ...BusinessServiceComputed,
    ...RiskTypeComputed,
    ...ChangeTypeComputed,
    ...ReleaseTypeComputed,
    ...ReleaseRiskTypeComputed,
    ...ReleaseReasonTypeComputed,
    ...ReasonTypeComputed,
    ...SourceComputed,
    ...SoftwareTypeComputed,
    ...AssetTypeComputed,
    ...ContractTypeComputed,
    ...ProjectTypeComputed,
    ...ProjectRiskTypeComputed,
    ...LicenseComputed,
    // computed module name (parentModuleName) for common item like status, form etc which are accept asset module
    parentModuleName() {
      if (
        [
          this.$constants.ASSET_HARDWARE,
          this.$constants.ASSET_SOFTWARE,
          this.$constants.ASSET_NON_IT,
          this.$constants.ASSET_CONSUMABLE,
        ].indexOf(this.moduleName) >= 0
      ) {
        return this.$constants.ASSET
      }
      return this.moduleName
    },
  },
  created() {
    if (this.preventApi) {
      return
    }
    // api calls here --> start
    if (this.parentModuleName === this.$constants.ASSET) {
      this.getAssetGroupOptions()
    }
    if (this.moduleName === this.$constants.SOFTWARE_LICENSE) {
      this.getSoftwareLicenseTypeOptions()
    }
    if (this.moduleName === this.$constants.PURCHASE) {
      this.getGlCodeOptions()
      this.getCostCenterOptions()
    }
    if (this.moduleName === this.$constants.USER_SURVEY) {
      this.getScheduleSurveyOptions()
    }
    if (
      this.moduleName === this.$constants.USER_SURVEY ||
      (this.moduleName === this.$constants.REQUEST &&
        this.availableModulesInLicense.indexOf(
          this.$constants.SERVICE_CATALOG
        ) >= 0)
    ) {
      this.getServiceCatalogOptions()
    }
    // api calls here --> end
    if (
      this.moduleName === this.$constants.PROBLEM ||
      this.parentModuleName === this.$constants.ASSET
    ) {
      // fetch problem specific vuex things
      if (!this.isBusinessServiceLoaded) {
        this.fetchBusinessServices()
      }
    }
    if (this.moduleName === this.$constants.CHANGE) {
      // fetch problem specific vuex things
      if (!this.isRiskTypeLoaded) {
        this.fetchRiskTypes()
      }
      if (!this.isChangeTypeLoaded) {
        this.fetchChangeTypes()
      }
      if (!this.isReasonTypeLoaded) {
        this.fetchReasonTypes()
      }
    }
    if (this.moduleName === this.$constants.RELEASE) {
      // fetch problem specific vuex things
      if (!this.isReleaseRiskTypeLoaded) {
        this.fetchReleaseRiskTypes()
      }
      if (!this.isReleaseTypeLoaded) {
        this.fetchReleaseTypes()
      }
      if (!this.isReleaseReasonTypeLoaded) {
        this.fetchReleaseReasonTypes()
      }
    }
    if (this.moduleName === this.$constants.CONTRACT) {
      // fetch problem specific vuex things
      if (!this.isContractTypesLoaded) {
        this.fetchContractTypes()
      }
    }
    if (this.moduleName === this.$constants.PROJECT) {
      if (!this.isProjectTypeLoaded) {
        this.fetchProjectTypes()
      }
    }
    if (this.moduleName === this.$constants.PROJECT) {
      if (!this.isProjectRiskTypeLoaded) {
        this.fetchProjectRiskTypes()
      }
    }
  },
  methods: {
    ...BusinessServiceMethods,
    ...RiskTypeMethods,
    ...ChangeTypeMethods,
    ...ReasonTypeMethods,
    ...ReleaseTypeMethods,
    ...ReleaseRiskTypeMethods,
    ...ReleaseReasonTypeMethods,
    ...ContractTypeMethods,
    ...ProjectTypeMethods,
    ...ProjectRiskTypeMethods,
    getSupportData() {
      if (this.moduleName === this.$constants.KNOWLEDGE) {
        return
      }
      getSupportDataApi(this.moduleName).then((data) => {
        this.supportData = Object.freeze(
          data.qualifications.filter((qualification) => {
            return (
              (
                qualification.operators.filter(
                  (operator) => fieldValueOperators.indexOf(operator.key) >= 0
                ) || []
              ).length > 0
            )
          })
        )
      })
    },
    fetchRequesters(ids) {
      if (ids === 'current_user') {
        return
      }
      return getUsersApi(
        'requester',
        { ids },
        undefined,
        undefined,
        undefined,
        {
          archived: true,
        }
      ).then(({ items }) => {
        this.requesterOptions = [
          {
            text: this.$tc('unassigned'),
            key: 0,
            label: this.$tc('unassigned'),
            value: 0,
            id: 0,
            avatar: 'user',
            avatarType: 'icon',
          },
        ].concat(
          items.map((i) => ({
            ...i,
            key: i.id,
            text: i.text,
            archived: false,
          }))
        )
      })
    },
    fetchProducts(ids) {
      return getProductCatalogsApi({ ids }).then(({ items }) => {
        this.productOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.text,
        }))
      })
    },
    fetchVendors(ids) {
      return getVendorCatalogsApi({ ids }).then(({ items }) => {
        this.vendorOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.text,
        }))
      })
    },
    fetchRemoteOffices(ids) {
      return getRemoteOfficesApi({ ids }).then(({ items }) => {
        this.remoteOfficeOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.name,
        }))
      })
    },
    fetchTemplates(moduleName) {
      return getTemplatesApi({ moduleName }).then(({ items }) => {
        this.templateOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.name,
        }))
      })
    },
    fetchDeploymentPolicies(ids) {
      return getDeploymentPoliciesApi({ ids }).then(({ items }) => {
        this.deploymentPolicyOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.name,
        }))
      })
    },
    getServiceCatalogOptions(ids) {
      const filter = {}
      if (ids) {
        filter.ids = ids
      }
      getServiceCatalogsApi(filter).then(({ items }) => {
        this.serviceCatalogOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.name,
        }))
      })
    },
    getScheduleSurveyOptions() {
      getScheduleSurveysApi().then(({ items }) => {
        this.scheduleSurveyOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.name,
        }))
      })
    },
    getGlCodeOptions() {
      getGlCodesApi({}).then(({ items }) => {
        this.glCodeOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.glCode,
        }))
      })
    },
    getCostCenterOptions() {
      getCostCentersApi({}).then(({ items }) => {
        this.costCenterOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.name,
        }))
      })
    },
    getAssetGroupOptions() {
      getAssetGroupsApi({}).then(({ items }) => {
        this.assetGroupOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.name,
        }))
      })
    },
    getSoftwareLicenseTypeOptions() {
      getSoftwareLicenseTypesApi({}).then(({ items }) => {
        this.softwareLicenseTypeOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.name,
        }))
      })
    },
    getCompanyOptions() {
      getCompaniesApi({}, undefined, undefined, {
        archived: true,
      }).then(({ items }) => {
        this.companyOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.displayName,
        }))
      })
    },
    getSSOConfigOptions() {
      searchSsoConfigApi({}).then(({ items }) => {
        this.ssoConfigOptions = items.map((t) => ({
          ...t,
          key: t.id,
          text: t.name,
        }))
      })
    },
    // fetch archived options for saved search data
    fetchFn(ids, vuexOptionKey) {
      const apiMap = {
        departmentOptions: getDepartmentsApi,
        technicianOptions: getUsersApi,
        technicianGroupsOptions: getGroupsApi,
        '{moduleName}Categories': searchCategoriesApi,
        assetTypes: searchAssetTypesApi,
        locationOptions: searchLocationsApi,
      }
      const archivedIds = ids.filter((id) => {
        if (['my_group', 'current_user'].indexOf(id) >= 0) {
          return false
        }
        let interpolatedKey = vuexOptionKey.replace(
          '{moduleName}',
          this.moduleName
        )
        let allItems = this[interpolatedKey]
        if (
          [
            '{moduleName}Categories',
            'locationOptions',
            'departmentOptions',
            'dependent',
            'assetTypes',
          ].indexOf(vuexOptionKey) >= 0
        ) {
          allItems = flattenRecursive(this[interpolatedKey])
        }
        return allItems.map((o) => o.key || o.id || o.value).indexOf(id) === -1
      })
      const archivedIdsQual = buildRelationalQualificationStructure(
        'id',
        'in',
        archivedIds,
        'long',
        'db'
      )
      switch (vuexOptionKey) {
        case 'departmentOptions':
          return apiMap[vuexOptionKey](
            { quals: [archivedIdsQual] },
            undefined,
            undefined,
            { archived: true }
          )
        case 'technicianOptions':
          return apiMap[vuexOptionKey](
            'technician',
            { ids: archivedIds },
            undefined,
            undefined,
            undefined,
            { archived: true }
          )
        case 'technicianGroupsOptions':
          return apiMap[vuexOptionKey](
            'technician',
            { quals: [archivedIdsQual] },
            undefined,
            undefined,
            { archived: true }
          )
        case '{moduleName}Categories':
          return apiMap[vuexOptionKey](
            this.moduleName,
            { quals: [archivedIdsQual] },
            undefined,
            undefined,
            { archived: true }
          )
        case 'assetTypes':
        case 'locationOptions':
          return apiMap[vuexOptionKey](
            { quals: [archivedIdsQual] },
            undefined,
            undefined,
            { archived: true }
          )
        default:
          return undefined
      }
    },
    fetchArchivedOptions(missingIds, vuexOptionKey) {
      const ids = Array.isArray(missingIds) ? missingIds : [missingIds]
      if (ids.length > 0 && !this.fetchFn(ids, vuexOptionKey)) {
        return Promise.resolve()
      }
      return this.fetchFn(ids, vuexOptionKey).then((data) => {
        this.archivedOptionsMap = {
          ...this.archivedOptionsMap,
          [vuexOptionKey]: Object.freeze(
            (data.items || []).map((c) => ({
              color: c.color,
              key: c.id,
              text: `${c.name}`,
              disabled: c.archived || c.disabled,
              archived: c.archived,
            }))
          ),
        }
      })
    },
  },
  render() {
    return this.$scopedSlots.default()
  },
}
</script>
