import SortBy from 'lodash/sortBy'
import Constants from '@constants'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import projectStatus from '@modules/project/helpers/status'
import { SupportLevelOptions } from '@components/data-picker/_base-support-level-picker'
import {
  ApprovalStatusOptions,
  CheckerApprovalStatusOptions,
} from '@components/data-picker/_base-approval-status-picker'
// import { SurveyStatusOptions } from '@components/data-picker/survey-status-picker'
import { EscalationLevelOptions } from '@data/escalation-level'
import {
  flattenFields,
  getOperatorMaps,
  buildInputTypeForField,
  buildValueTypeForField,
  buildOperatorsForField,
  getAdditionalFieldProps,
} from '@data/form'
import { unaryOperators } from '@data/operator'
import { dropdownTypeMap } from '@data/qualification'

import { osPlatformOptions as patchOsPlatformOptions } from '@modules/computer/helpers/options-map'
const __t = getRootTranslator()
const __tc = getRootPluaralTranslator()

const excludedField = [
  'section',
  'label',
  'upload',
  'textarea',
  'api',
  'richtextarea',
]

export const customSystemFileMappingMap = {
  technician_group: {
    vuexOptionKey: 'technicianGroupsOptions',
    inputType: 'dropdown',
    valueType: 'long',
  },
  requester_group: {
    vuexOptionKey: 'requesterGroupsOptions',
    inputType: 'dropdown',
    valueType: 'long',
  },
  department: {
    vuexOptionKey: 'departmentOptions',
    paramType: 'variable',
    inputType: 'department',
    valueType: 'long',
  },
  location: {
    vuexOptionKey: 'locationOptions',
    inputType: 'location',
    paramType: 'variable',
    valueType: 'long',
  },
  category: {
    vuexOptionKey: '{moduleName}Categories',
    paramType: 'variable',
    inputType: 'category',
    valueType: 'long',
  },
  requester: {
    vuexOptionKey: 'requesterOptions',
    inputType: 'requester',
    valueType: 'long',
  },
  technician: {
    vuexOptionKey: 'technicianOptions',
    inputType: 'dropdown',
    valueType: 'long',
  },
}

/**
 * exclusive is for if this qualification should be shown even if its selected once
 */

export const computerExcludeSearchParams = [
  'os_platform',
  'lastRefreshCallTime',
]
export const endpointcomputerExcludeSearchParams = [
  'os_platform',
  'lastRefreshCallTime',
  'healthType',
]

export const discoveryAgentExcludeSearchParams = [
  'healthType',
  'remoteOfficeId',
  'tags',
  'patchType',
]

export const mobileAgentExcludeSearchParams = [
  'architecture',
  'healthType',
  'remoteOfficeId',
  'tags',
  'hostName',
  'os_platform',
  'patchType',
]

export const discoveryPollerConfigExcludeSearchParams = [
  'healthType',
  'remoteOfficeId',
  'architecture',
  'tags',
  'agent_activation_status',
  'patchType',
]

export const myTeamExcludeSearchParams = [
  'userLogOnName',
  'departmentId',
  'locationId',
  'vip',
  'allowToLogin',
  'doNotDisturb',
  'companyIds',
  'authenticationSource',
  'disabled',
  'allowDelegateApprovalTeam',
  'allowDelegateApproval',
  'allowWorkDelegationForSelf',
  'allowWorkDelegationForTeam',
  'ssoConfigId',
]

export const assetMovementAssetSelectionFixedSearchParams = () => {
  const operatorMaps = getOperatorMaps()
  return [
    {
      key: generateId(),
      name: __t('id'),
      paramName: 'name',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.text],
      supportedIn: ['send_to_repair', 'return_from_repair', 'asset_transfer'],
    },
    {
      key: generateId(),
      name: __t('name'),
      paramName: 'displayName',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      supportedIn: ['send_to_repair', 'return_from_repair', 'asset_transfer'],
    },
    {
      key: generateId(),
      name: __t('status'),
      paramName: 'statusId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'assetStatus',
      exclusive: true,
      operators: [...operatorMaps.list],
      supportedIn: ['send_to_repair', 'asset_transfer'],
    },
    {
      key: generateId(),
      name: __tc('barcode'),
      paramName: 'barCode',
      inputType: 'tags',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.list, ...operatorMaps.contains],
      supportedIn: ['send_to_repair'],
    },
    {
      key: generateId(),
      name: __t('sent_date'),
      paramName: 'asset_movement_date',
      paramType: 'variable',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      supportedIn: ['return_from_repair', 'asset_transfer'],
    },
    {
      key: generateId(),
      name: __t('reference_number'),
      paramName: 'asset_movement_reference_number',
      paramType: 'variable',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      supportedIn: ['return_from_repair', 'asset_transfer'],
    },
    {
      key: generateId(),
      name: __t('expected_return_date'),
      paramName: 'asset_movement_expected_return_date',
      paramType: 'variable',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      supportedIn: ['return_from_repair', 'asset_transfer'],
    },
    {
      key: generateId(),
      name: __tc('asset_type'),
      paramName: 'assetTypeId',
      vuexOptionKey: 'assetTypes',
      paramType: 'variable',
      inputType: 'assetType',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      supportedIn: ['asset_transfer'],
    },
  ]
}

export function getSearchSources(
  moduleName,
  resourceFields,
  supportData,
  onlyCustomFields = false
) {
  const operatorMaps = getOperatorMaps()
  let projectOptions = projectStatus()
  let allSources = [
    {
      key: generateId(),
      name: __t('id'),
      paramName: 'name',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.text],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.TASK,
        Constants.CONTRACT,
        Constants.PURCHASE,
        Constants.USER_SURVEY,
        Constants.PROJECT,
        Constants.PATCH_REMOTE_DEPLOYMENT,
        Constants.PACKAGES_REMOTE_DEPLOYMENT,
        Constants.REGISTRY_REMOTE_DEPLOYMENT,
        Constants.ASSET_MOVEMENT,
      ],
    },
    {
      key: generateId(),
      name: __t('id'),
      paramName: 'name',
      inputType: 'multiStringValue',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.listCaseInsensitive],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
        Constants.BASELINE,
      ],
    },
    {
      key: generateId(),
      name: __t('name'),
      paramName: 'displayName',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      paramType: 'db',
      operators: [...operatorMaps.text],
      allowedModules: [Constants.BASELINE],
    },
    {
      key: generateId(),
      name: __t('subject'),
      paramName: 'subject',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.contains],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.TASK,
      ],
    },
    {
      key: generateId(),
      name: __t('name'),
      paramName: 'name',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.USER, Constants.SNMP_PROPERTY_DEVICE],
    },
    {
      key: generateId(),
      name: __tc('name'),
      paramName: 'report_name',
      paramType: 'variable',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.REPORT_HISTORY],
    },
    {
      key: generateId(),
      name: `${__tc('patch')} ${__t('id')}`,
      paramName: 'name',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: `${__t('name')}`,
      paramName: 'title',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      type: 'FlotoSubQueryRest',
      name: `${__tc('id')}`,
      paramName: 'subQuery|approval|name',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      showOnSupportPortal: true,
      options: dropdownTypeMap.approvalModuleSelectorOptions().options,
      operators: [...operatorMaps.text_without_unary_operator],
      allowedModules: [Constants.APPROVAL],
      moduleNameSelection: true,
    },
    {
      key: generateId(),
      type: 'FlotoSubQueryRest',
      name: `${__tc('name')}`,
      paramName: 'subQuery|approval|displayName',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      showOnSupportPortal: true,
      options: dropdownTypeMap
        .approvalModuleSelectorOptions()
        .options.filter((o) => o.key !== Constants.ASSET_MOVEMENT),
      operators: [...operatorMaps.text_without_unary_operator],
      allowedModules: [Constants.APPROVAL],
      moduleNameSelection: true,
    },
    {
      key: generateId(),
      type: 'FlotoSubQueryRest',
      name: `${__tc('type')}`,
      paramName: 'subQuery|approval|type',
      inputType: 'approvalModuleSelector',
      valueType: 'enum',
      exclusive: true,
      options: dropdownTypeMap.approvalModuleSelectorOptions().options,
      showOnSupportPortal: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.APPROVAL],
    },
    {
      key: generateId(),
      name: `${__tc('requested')} ${__tc('by')}`,
      paramName: 'createdById',
      inputType: 'requester',
      paramType: 'db',
      valueType: 'long',
      exclusive: true,
      showOnSupportPortal: true,
      vuexOptionKey: 'requesterOptions',
      operators: [...operatorMaps.list],
      allowedModules: [Constants.APPROVAL],
    },
    {
      key: generateId(),
      type: 'FlotoSubQueryRest',
      name: `${__tc('subject')}`,
      paramName: 'subQuery|approval|subject',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.text_without_unary_operator],
      allowedModules: [Constants.APPROVAL],
    },
    {
      key: generateId(),
      name: `${__t('uuid')}`,
      paramName: 'uuid',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: __t('patch_type'),
      paramName: 'patchType',
      inputType: 'dropdown',
      paramType: 'db',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.patchTypeOptions().options,
      allowedModules: [
        Constants.PATCH,
        Constants.AGENT,
        Constants.PATCH_COMPUTERS,
      ],
    },
    {
      key: generateId(),
      name: __t('system_oid'),
      paramName: 'systemOid',
      inputType: 'multiStringValue',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.list],
      paramType: 'db',
      allowedModules: [Constants.SNMP_PROPERTY_DEVICE],
    },
    {
      key: generateId(),
      name: __t('logon_name'),
      paramName: 'userLogOnName',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: __t('agent_id'),
      paramName: 'agent_id',
      paramType: 'variable',
      inputType: 'multiStringValue',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.listCaseInsensitive],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __t('agent_last_sync_date'),
      paramName: 'agent_last_sync_date',
      paramType: 'variable',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __t('agent_id'),
      paramName: 'name',
      inputType: 'multiStringValue',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.listCaseInsensitive],
      allowedModules: [
        Constants.AGENT,
        Constants.PATCH_COMPUTERS,
        Constants.PACKAGES_COMPUTERS,
      ],
    },
    {
      key: generateId(),
      name: `${__t('poller')} ${__t('id')}`,
      paramName: 'name',
      inputType: 'multiStringValue',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.listCaseInsensitive],
      allowedModules: [Constants.POLLER],
    },
    {
      key: generateId(),
      name: __t('agent_activation_status'),
      paramName: 'agent_activation_status',
      inputType: 'dropdown',
      paramType: 'variable',
      valueType: 'boolean',
      exclusive: true,
      options: dropdownTypeMap.agentActivationStatus().options,
      operators: [...operatorMaps.equal],
      allowedModules: [
        Constants.AGENT,
        Constants.PATCH_COMPUTERS,
        Constants.PACKAGES_COMPUTERS,
        Constants.ASSET_HARDWARE,
      ],
    },
    {
      key: generateId(),
      name: __t('host_name'),
      paramName: 'hostName',
      inputType: 'tags',
      valueType: 'string',
      exclusive: true,
      operators: [
        ...operatorMaps.listCaseInsensitive,
        ...operatorMaps.contains_only,
      ],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.AGENT,
        Constants.PATCH_COMPUTERS,
        Constants.PACKAGES_COMPUTERS,
        Constants.POLLER,
      ],
    },
    {
      key: generateId(),
      name: __t('domain_name'),
      paramName: 'domainName',
      inputType: 'multiStringValue',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.listCaseInsensitive],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __t('ip_address'),
      paramName: 'ipAddress',
      inputType: 'multiStringValue',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.listCaseInsensitive],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.AGENT,
        Constants.PATCH_COMPUTERS,
        Constants.PACKAGES_COMPUTERS,
        Constants.POLLER,
      ],
    },
    {
      key: generateId(),
      name: __t('platform'),
      paramName: 'os_platform',
      inputType: 'dropdown',
      paramType: 'variable',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.platform().options,
      allowedModules: [Constants.AGENT],
    },
    {
      key: generateId(),
      name: __t('platform'),
      paramName: 'platform',
      inputType: 'dropdown',
      paramType: 'db',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: patchOsPlatformOptions(),
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: __t('os_name'),
      paramName: 'osName',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.contains],
      allowedModules: [
        Constants.AGENT,
        Constants.PATCH_COMPUTERS,
        Constants.PACKAGES_COMPUTERS,
        Constants.POLLER,
      ],
    },
    {
      key: generateId(),
      name: __tc('company'),
      paramName: 'companyId',
      vuexOptionKey: 'companyOptions',
      inputType: 'company',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.USER_SURVEY,
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
      ],
    },
    {
      key: generateId(),
      name: __tc('company'),
      paramName: 'companyIds',
      vuexOptionKey: 'companyOptions',
      inputType: 'company',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.members_exist_with_not_any_members_exist],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: __t('version'),
      paramName: 'version',
      inputType: 'tags',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.ASSET_SOFTWARE],
    },
    {
      key: generateId(),
      name: __t('version'),
      paramName: 'agentVersion',
      inputType: 'tags',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.AGENT],
    },
    {
      key: generateId(),
      name: `${__t('poller')} ${__t('version')}`,
      paramName: 'pollerVersion',
      inputType: 'tags',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.POLLER],
    },
    {
      key: generateId(),
      name: __t('version'),
      paramName: 'osVersion',
      inputType: 'tags',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.PATCH_COMPUTERS, Constants.PACKAGES_COMPUTERS],
    },
    {
      key: generateId(),
      name: __t('architecture'),
      paramName: 'architecture',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.osArchitecture().options,
      allowedModules: [
        Constants.PATCH_COMPUTERS,
        Constants.PACKAGES_COMPUTERS,
        Constants.AGENT,
      ],
    },
    {
      key: generateId(),
      name: ` ${__tc('asset')} ${__tc('condition')}`,
      paramName: 'assetCondition',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.assetCondition().options,
      allowedModules: [Constants.ASSET_HARDWARE, Constants.ASSET_NON_IT],
    },
    {
      key: generateId(),
      name: ` ${__tc('movement')} ${__tc('status')}`,
      paramName: 'movementStatus',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.assetMovementStatus().options,
      allowedModules: [Constants.ASSET_HARDWARE, Constants.ASSET_NON_IT],
    },
    {
      key: generateId(),
      name: __t('system_health'),
      paramName: 'healthType',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.healthType().options,
      allowedModules: [Constants.AGENT, Constants.PATCH_COMPUTERS],
    },
    {
      key: generateId(),
      name: __tc('remote_office'),
      paramName: 'remoteOfficeId',
      inputType: 'remoteOffice',
      valueType: 'long',
      vuexOptionKey: 'remoteOfficeOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.AGENT,
        Constants.PATCH_COMPUTERS,
        Constants.PACKAGES_COMPUTERS,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('request')} ${__tc('template')}`,
      paramName: 'templateId',
      inputType: 'template',
      valueType: 'long',
      vuexOptionKey: 'templateOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.REQUEST],
    },
    {
      key: generateId(),
      name: __t('agent_last_sync_date'),
      paramName: 'lastRefreshCallTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.AGENT],
    },
    {
      key: generateId(),
      name: `${__t('poller')} ${__t('last_sync_date')}`,
      paramName: 'lastRefreshCallTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.POLLER],
    },
    {
      key: generateId(),
      name: __t('name'),
      paramName: 'displayName',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
        Constants.CONTRACT,
        Constants.SOFTWARE_LICENSE,
        Constants.PURCHASE,
        Constants.USER_SURVEY,
        Constants.PATCH_REMOTE_DEPLOYMENT,
        Constants.PACKAGES_REMOTE_DEPLOYMENT,
        Constants.REGISTRY_REMOTE_DEPLOYMENT,
        Constants.PROJECT,
      ],
    },
    {
      key: generateId(),
      name: __t('hardware_serial_number'),
      paramName: 'HardwarePropertyGroup.serialNumber',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __t('non_it_serial_number'),
      paramName: 'NonITPropertyGroup.serialNumber',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.ASSET_NON_IT],
    },
    {
      key: generateId(),
      name: __t('warranty_expiration_date'),
      paramName: 'NonITPropertyGroup.warrentyExpirationDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_NON_IT],
    },
    {
      key: generateId(),
      name: __t('warranty_expiration_date'),
      paramName: 'HardwarePropertyGroup.warrentyExpirationDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __t('audit_date'),
      paramName: 'NonITPropertyGroup.auditDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_NON_IT],
    },
    {
      key: generateId(),
      name: __t('audit_date'),
      paramName: 'HardwarePropertyGroup.auditDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __t('architecture'),
      paramName: 'ComputerPropertyGroup.osArchitecture',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      options: dropdownTypeMap.osArchitecture().options,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __t('software_software_name'),
      paramName: 'SoftwareComponent.softwareName',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __t('computer_os_name'),
      paramName: 'ComputerPropertyGroup.osName',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __tc('contact_no'),
      paramName: 'contanct_number',
      paramType: 'variable',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.text_without_not_equal],
      allowedModules: [Constants.USER],
    },

    {
      key: generateId(),
      name: __t('status'),
      paramName: 'statusId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: '{moduleName}Status',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.TASK,
        Constants.PURCHASE,
      ],
    },
    // for asset
    {
      key: generateId(),
      name: __t('status'),
      paramName: 'statusId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: `${Constants.ASSET}Status`,
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
      ],
    },
    //  for asset movement
    {
      key: generateId(),
      name: __t('status'),
      paramName: 'status',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: '{moduleName}Status',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.ASSET_MOVEMENT],
    },
    {
      key: generateId(),
      name: `${__tc('approval')} ${__t('status')}`,
      paramName: 'approvalStatus',
      inputType: 'dropdown',
      valueType: 'enum',
      options: ApprovalStatusOptions(),
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.PURCHASE,
        Constants.ASSET_MOVEMENT,
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_HARDWARE,
        Constants.KNOWLEDGE,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('checker_approval')}`,
      paramName: 'checkerApprovalStatus',
      inputType: 'dropdown',
      valueType: 'enum',
      options: CheckerApprovalStatusOptions(),
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.ASSET_NON_IT, Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: `${__t('status')}`,
      paramName: 'surveyStatus',
      inputType: 'dropdown',
      valueType: 'enum',
      options: dropdownTypeMap.surveyStatus().options,
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.USER_SURVEY],
    },
    {
      key: generateId(),
      name: `${__t('source')}`,
      paramName: 'source',
      inputType: 'dropdown',
      valueType: 'enum',
      options: dropdownTypeMap.surveySource().options,
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.USER_SURVEY],
    },
    {
      key: generateId(),
      name: `${__t('service_catalog')}`,
      paramName: 'serviceCatalogId',
      inputType: 'service_catalog',
      valueType: 'long',
      vuexOptionKey: 'serviceCatalogOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.USER_SURVEY],
    },
    {
      key: generateId(),
      name: `${__t('schedule')} ${__t('id')}`,
      paramName: 'scheduleId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'scheduleSurveyOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.USER_SURVEY],
    },
    {
      key: generateId(),
      name: __tc('product'),
      paramName: 'productId',
      inputType: 'product',
      valueType: 'long',
      vuexOptionKey: 'productOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
        Constants.SOFTWARE_LICENSE,
      ],
    },
    {
      key: generateId(),
      name: __tc('vendor'),
      paramName: 'vendorId',
      inputType: 'vendor',
      valueType: 'long',
      vuexOptionKey: 'vendorOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.PROJECT,
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.CONTRACT,
        Constants.PURCHASE,
        Constants.ASSET_MOVEMENT,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('order_number')}`,
      paramName: 'orderNumber',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.PURCHASE],
    },
    {
      key: generateId(),
      name: `${__tc('movement')} ${__tc('type')}`,
      paramName: 'movementType',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      paramType: 'db',
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.assetMovementTypeOptions().options,
      allowedModules: [Constants.ASSET_MOVEMENT],
    },
    // refactor requester_email to requester
    {
      key: generateId(),
      name: __tc('requester'),
      paramName: 'requesterId',
      inputType: 'requester',
      valueType: 'long',
      exclusive: true,
      vuexOptionKey: 'requesterOptions',
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.CO_OPS_CALL_HISTORY,
        Constants.CO_OPS_TEAM_ACTIVITY_SUMMARY,
      ],
    },
    {
      key: generateId(),
      name: __tc('created_by'),
      paramName: 'createdById',
      inputType: 'requester',
      valueType: 'long',
      exclusive: true,
      showOnSupportPortal: true,
      vuexOptionKey: 'requesterOptions',
      operators: [...operatorMaps.list],
      allowedModules: [Constants.REQUEST],
    },
    {
      key: generateId(),
      name: __tc('last_barcode_qrcode_scan_by'),
      paramName: 'lastQRAndBarcodeScanBy',
      inputType: 'requester',
      valueType: 'long',
      exclusive: true,
      vuexOptionKey: 'requesterOptions',
      operators: [...operatorMaps.list],
      allowedModules: [Constants.ASSET_HARDWARE, Constants.ASSET_NON_IT],
    },
    {
      key: generateId(),
      name: `${__tc('last_barcode_qrcode_scan_date')}`,
      paramName: 'lastQRAndBarcodeScanDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_HARDWARE, Constants.ASSET_NON_IT],
    },
    {
      key: generateId(),
      name: __tc('requester'),
      paramName: 'requesterId',
      inputType: 'requester',
      valueType: 'long',
      vuexOptionKey: 'requesterOptions',
      options: dropdownTypeMap.assignee().options,
      exclusive: true,
      operators: [...operatorMaps.listWithEqual],
      allowedModules: [Constants.ASSET_MOVEMENT],
    },
    {
      key: generateId(),
      name: `${__tc('from')} ${__tc('location')}`,
      paramName: 'fromLocation',
      vuexOptionKey: 'locationOptions',
      inputType: 'location',
      paramType: 'variable',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.ASSET_MOVEMENT],
    },
    {
      key: generateId(),
      name: `${__tc('to')} ${__tc('location')}`,
      paramName: 'toLocation',
      vuexOptionKey: 'locationOptions',
      inputType: 'location',
      paramType: 'variable',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.ASSET_MOVEMENT],
    },
    {
      key: generateId(),
      name: `${__tc('movement')} ${__t('date')}`,
      paramName: 'movementDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_MOVEMENT],
    },
    {
      key: generateId(),
      name: `${__tc('expected_return_date')}`,
      paramName: 'expectedDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_MOVEMENT],
    },
    {
      key: generateId(),
      name: ` ${__tc('asset')} ${__tc('type')}`,
      paramName: 'assetType',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.assetTypeEnum().options,
      allowedModules: [Constants.ASSET_MOVEMENT],
    },
    {
      key: generateId(),
      name: __tc('requester'),
      paramName: 'requesterId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.PURCHASE],
    },
    // @TODO ask for requester list to ashishbhai
    {
      key: generateId(),
      name: __tc('used_by'),
      paramName: 'used_by_users',
      paramType: 'variable',
      inputType: 'requester',
      valueType: 'long',
      vuexOptionKey: 'requesterOptions',
      exclusive: true,
      operators: [...operatorMaps.members_exist_with_not_any_members_exist],
      allowedModules: [Constants.ASSET_HARDWARE, Constants.ASSET_NON_IT],
    },
    {
      key: generateId(),
      name: __tc('managed_by'),
      paramName: 'managedById',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      options: dropdownTypeMap.managedById().options,
      exclusive: true,
      operators: [...operatorMaps.listWithEqual],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('managed_by')} ${__tc('group')}`,
      paramName: 'managedByGroupId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianGroupsOptions',
      options: dropdownTypeMap.managedByGroupId().options,
      exclusive: true,
      operators: [...operatorMaps.listWithEqual],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('asset')} ${__tc('group')}`,
      paramName: 'assetGroupId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'assetGroupOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('license')} ${__tc('type')}`,
      paramName: 'licenceTypeId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'softwareLicenseTypeOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.SOFTWARE_LICENSE],
    },
    {
      key: generateId(),
      name: __tc('email'),
      paramName: 'email',
      inputType: 'emails',
      valueType: 'string',
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.list, ...operatorMaps.blank_not_blank],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: __tc('under_change_control'),
      paramName: 'underChangeControl',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: dropdownTypeMap.booleanOptions().options,
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __tc('variance_detection'),
      paramName: 'varianceDetected',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: dropdownTypeMap.booleanOptions().options,
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __tc('do_not_disturb'),
      paramName: 'doNotDisturb',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: dropdownTypeMap.booleanOptions().options,
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: __tc('status'),
      paramName: 'disabled',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: dropdownTypeMap.blockedStatusOptions().options,
      exclusive: true,
      operators: [...operatorMaps.boolean],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: __tc('allow_to_login'),
      paramName: 'allowToLogin',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: dropdownTypeMap.booleanOptions().options,
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: __t('priority'),
      paramName: 'priorityId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'priorityOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.TASK,
        Constants.PROJECT,
      ],
    },
    {
      key: generateId(),
      name: __t('impact'),
      paramName: 'impactId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'impactOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
      ],
    },
    {
      key: generateId(),
      name: __t('source'),
      paramName: 'sourceId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'sourceOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.REQUEST],
    },
    {
      key: generateId(),
      name: `${__tc('known_error')}`,
      paramName: 'knownError',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: [
        {
          text: __tc('yes'),
          key: true,
          singleValueSelection: true,
        },
        {
          text: __tc('no'),
          key: false,
          singleValueSelection: true,
        },
      ],
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.PROBLEM],
    },
    {
      key: generateId(),
      name: `${__tc('nature_of_problem')}`,
      paramName: 'natureOfProblem',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.equal],
      options: [
        {
          text: __tc('proactive'),
          key: 'proactive',
          singleValueSelection: true,
        },
        {
          text: __tc('reactive'),
          key: 'reactive',
          singleValueSelection: true,
        },
      ],
      allowedModules: [Constants.PROBLEM],
    },
    {
      key: generateId(),
      name: `${__tc('business')} ${__tc('service', 2)}`,
      paramName: 'businessServiceId',
      inputType: 'dropdown',
      vuexOptionKey: 'businessServiceOptions',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.PROBLEM,
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
      ],
    },
    {
      key: generateId(),
      name: __t('urgency'),
      paramName: 'urgencyId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'urgencyOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
      ],
    },
    {
      key: generateId(),
      name: __tc('due_by'),
      paramName: 'dueBy',
      paramType: 'variable',
      inputType: 'dueBy',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('first')} ${__tc('response')} ${__tc('due_by')}`,
      paramName: 'responseDue',
      paramType: 'variable',
      inputType: 'dueBy',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.REQUEST],
    },
    {
      key: generateId(),
      name: __t('assignee'),
      paramName: 'technicianId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      options: dropdownTypeMap.assignee().options,
      exclusive: true,
      operators: [...operatorMaps.listWithEqual],
      searchableDropdown: true,
      allowedModules: [
        Constants.REQUEST,
        Constants.TASK,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
      ],
    },
    {
      key: generateId(),
      name: __tc('technician_group'),
      paramName: 'groupId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianGroupsOptions',
      options: dropdownTypeMap.groupId().options,
      exclusive: true,
      operators: [...operatorMaps.listWithEqual],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.TASK,
      ],
    },
    {
      key: generateId(),
      name: __tc('category'),
      paramName: 'categoryId',
      vuexOptionKey: '{moduleName}Categories',
      paramType: 'variable',
      inputType: 'category',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('software')} ${__tc('category')}`,
      paramName: 'swCategoryId',
      vuexOptionKey: '{moduleName}Categories',
      paramType: 'variable',
      inputType: 'category',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.ASSET_SOFTWARE],
    },
    {
      key: generateId(),
      name: `${__t('installation')} ${__tc('notification')}`,
      paramName: 'swComplianceAlert',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: [
        {
          text: __tc('yes'),
          key: true,
          singleValueSelection: true,
        },
        {
          text: __tc('no'),
          key: false,
          singleValueSelection: true,
        },
      ],
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.ASSET_SOFTWARE],
    },
    {
      key: generateId(),
      name: `${__tc('type')}`,
      paramName: 'requestType',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.requestType().options,
      allowedModules: [Constants.REQUEST],
    },
    {
      key: generateId(),
      name: `${__tc('origin')}`,
      paramName: 'origin',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.origin().options,
      allowedModules: [Constants.ASSET_HARDWARE, Constants.ASSET_SOFTWARE],
    },
    {
      key: generateId(),
      name: `${__tc('source')}`,
      paramName: 'projectSource',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.projectSource().options,
      allowedModules: [Constants.PROJECT],
    },
    // patch
    {
      key: generateId(),
      name: `${__tc('patch')} ${__tc('category')}`,
      paramName: 'category',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.patchUpdateCategory().options,
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: `${__tc('severity')}`,
      paramName: 'severity',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.patchSeverity().options,
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: `${__tc('approval')} ${__tc('status')}`,
      paramName: 'patchApprovalStatus',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.patchApprovalStatus().options,
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: `${__tc('test')} ${__tc('status')}`,
      paramName: 'testStatus',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.patchTestStatus().options,
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: __t('release_date'),
      paramName: 'releaseDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: __t('bulletin_id'),
      paramName: 'bulletinId',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: __t('kb_number'),
      paramName: 'kbId',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: `${__tc('superseded')} ${__tc('status')}`,
      paramName: 'superseded',
      inputType: 'boolean',
      valueType: 'boolean',
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: `${__tc('download')} ${__tc('status')}`,
      paramName: 'downloadStatus',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.patchDownloadStatus().options,
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: `${__tc('patch')} ${__tc('source')}`,
      paramName: 'source',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      paramType: 'db',
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.patchSource().options,
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: `${__tc('patch')} ${__tc('status')}`,
      paramName: 'status',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      paramType: 'db',
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.patchStatus().options,
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: `${__tc('patch')} ${__tc('upload')} ${__tc('status')}`,
      paramName: 'uploadStatus',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      paramType: 'db',
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.patchUploadStatus().options,
      allowedModules: [Constants.PATCH],
    },
    // patch end
    {
      key: generateId(),
      name: __tc('tag', 2),
      paramName: 'tags',
      inputType: 'tags',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.tag, ...operatorMaps.empty_not_empty],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
        Constants.PURCHASE,
        Constants.USER_SURVEY,
        Constants.PROJECT,
        Constants.PATCH,
        Constants.AGENT,
        Constants.PATCH_COMPUTERS,
        Constants.PACKAGES_COMPUTERS,
      ],
    },
    {
      key: generateId(),
      name: __tc('tag', 2),
      paramName: 'tags',
      inputType: 'tags',
      valueType: 'string',
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.tag, ...operatorMaps.empty_not_empty],
      allowedModules: [Constants.KNOWLEDGE],
    },
    {
      key: generateId(),
      name: __tc('barcode'),
      paramName: 'barCode',
      inputType: 'tags',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.list, ...operatorMaps.contains],
      allowedModules: [Constants.ASSET_HARDWARE, Constants.ASSET_NON_IT],
    },
    {
      key: generateId(),
      name: __tc('asset_type'),
      paramName: 'assetTypeId',
      vuexOptionKey: 'assetTypes',
      paramType: 'variable',
      inputType: 'assetType',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
        Constants.SNMP_PROPERTY_DEVICE,
        Constants.BASELINE,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('created_date')}`,
      paramName: 'createdTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_MOVEMENT, Constants.APPROVAL],
    },
    {
      key: generateId(),
      name: __tc('department'),
      paramName: 'departmentId',
      vuexOptionKey: 'departmentOptions',
      inputType: 'department',
      paramType: 'variable',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.REQUEST,
        Constants.USER,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.CONTRACT,
        Constants.USER_SURVEY,
      ],
    },
    {
      key: generateId(),
      name: __tc('authentication_source'),
      paramName: 'authenticationSource',
      inputType: 'dropdown',
      paramType: 'db',
      valueType: 'enum',
      options: dropdownTypeMap.authenticationSourceOptions().options,
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: __tc('department'),
      paramName: 'assignedDepartmentId',
      vuexOptionKey: 'departmentOptions',
      // options: [{ text: __t('none'), key: 0, id: 0 }],
      paramType: 'variable',
      inputType: 'department',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
      ],
    },
    {
      key: generateId(),
      name: __tc('location'),
      paramName: 'locationId',
      vuexOptionKey: 'locationOptions',
      inputType: 'location',
      paramType: 'variable',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.REQUEST,
        Constants.USER,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.ASSET_HARDWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
        Constants.USER_SURVEY,
        Constants.PROJECT,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('change_type')}`,
      paramName: 'changeTypeId',
      inputType: 'dropdown',
      vuexOptionKey: 'changeTypeOptions',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.CHANGE],
    },
    {
      key: generateId(),
      name: `${__tc('change_risk')}`,
      paramName: 'riskTypeId',
      inputType: 'dropdown',
      vuexOptionKey: 'riskTypeOptions',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.CHANGE],
    },
    {
      key: generateId(),
      name: `${__tc('project_risk')}`,
      paramName: 'riskTypeId',
      inputType: 'dropdown',
      vuexOptionKey: 'projectRiskTypeOptions',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.PROJECT],
    },
    {
      key: generateId(),
      name: `${__tc('project_type')}`,
      paramName: 'projectTypeId',
      inputType: 'dropdown',
      options: [{ text: __t('none'), key: 0, id: 0 }],
      vuexOptionKey: 'projectTypeOptions',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.PROJECT],
    },
    {
      key: generateId(),
      name: `${__tc('change')} ${__t('reason')}`,
      paramName: 'reasonType',
      inputType: 'dropdown',
      vuexOptionKey: 'reasonTypeOptions',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.CHANGE],
    },
    {
      key: generateId(),
      name: `${__tc('implementer')}`,
      paramName: 'changeImplementor',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.CHANGE],
    },
    {
      key: generateId(),
      name: `${__tc('manager')}`,
      paramName: 'changeManager',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.CHANGE],
    },
    {
      key: generateId(),
      name: `${__tc('reviewer')}`,
      paramName: 'changeReviewer',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.CHANGE],
    },
    {
      key: generateId(),
      name: `${__tc('release_type')}`,
      paramName: 'releaseTypeId',
      inputType: 'dropdown',
      vuexOptionKey: 'releaseTypeOptions',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.RELEASE],
    },
    {
      key: generateId(),
      name: `${__tc('release_risk')}`,
      paramName: 'riskTypeId',
      inputType: 'dropdown',
      vuexOptionKey: 'releaseReleaseRiskTypeOptions',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.RELEASE],
    },
    {
      key: generateId(),
      name: `${__tc('business')} ${__tc('service', 2)}`,
      paramName: 'affectedServices',
      inputType: 'dropdown',
      vuexOptionKey: 'businessServiceOptions',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.tag],
      allowedModules: [Constants.RELEASE],
    },
    {
      key: generateId(),
      name: `${__tc('release')} ${__t('reason')}`,
      paramName: 'reasonType',
      inputType: 'dropdown',
      vuexOptionKey: 'releaseReasonTypeOptions',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.RELEASE],
    },
    {
      key: generateId(),
      name: `${__tc('release')} ${__tc('engineer')}`,
      paramName: 'releaseEngineer',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.RELEASE],
    },
    {
      key: generateId(),
      name: `${__tc('release')} ${__tc('manager')}`,
      paramName: 'releaseManager',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.RELEASE],
    },
    {
      key: generateId(),
      name: `${__tc('qa')} ${__tc('manager')}`,
      paramName: 'qaManager',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.RELEASE],
    },
    {
      key: generateId(),
      name: `${__tc('release')} ${__tc('reviewer')}`,
      paramName: 'releaseReviewer',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.RELEASE],
    },
    {
      key: generateId(),
      name: `${__tc('schedule')} ${__tc('start_date')}`,
      paramName: 'startDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.CHANGE, Constants.RELEASE],
    },
    {
      key: generateId(),
      name: `${__tc('expiry')} ${__tc('date')}`,
      paramName: 'expiryDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.SOFTWARE_LICENSE],
    },
    {
      key: generateId(),
      name: `${__tc('event')} ${__tc('time')}`,
      paramName: 'createdTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.REPORT_HISTORY],
    },
    {
      key: generateId(),
      name: `${__tc('event')} ${__tc('type')}`,
      paramName: 'eventType',
      inputType: 'dropdown',
      valueType: 'enum',
      options: [
        {
          key: 'download',
          text: __tc('download'),
        },
        {
          key: 'export',
          text: __tc('export'),
        },
      ],
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.REPORT_HISTORY],
    },
    {
      key: generateId(),
      name: `${__tc('project')} ${__tc('start_date')}`,
      paramName: 'startDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PROJECT],
    },
    {
      key: generateId(),
      name: `${__tc('project')} ${__tc('end_date')}`,
      paramName: 'endDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PROJECT],
    },
    {
      key: generateId(),
      name: `${__tc('schedule')} ${__tc('end_date')}`,
      paramName: 'endDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.CHANGE, Constants.RELEASE],
    },
    {
      key: generateId(),
      name: __tc('support_level'),
      paramName: 'supportLevel',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list, ...operatorMaps.null_not_null],
      options: SupportLevelOptions(),
      allowedModules: [Constants.REQUEST],
    },
    {
      key: generateId(),
      name: __tc('escalation_level'),
      paramName: 'resolutionDueLevel',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: EscalationLevelOptions(),
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('requester_group')}`,
      paramName: 'requesterGroup',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'requesterGroupsOptions',
      exclusive: true,
      operators: [...operatorMaps.tag],
      allowedModules: [Constants.USER_SURVEY],
    },
    {
      key: generateId(),
      name: __t('status'),
      paramName: 'remoteDeployStage',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.remoteDeployStage().options,
      allowedModules: [
        Constants.PATCH_REMOTE_DEPLOYMENT,
        Constants.PACKAGES_REMOTE_DEPLOYMENT,
        Constants.REGISTRY_REMOTE_DEPLOYMENT,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('deployment_policy')}`,
      paramName: 'deploymentPolicyId',
      inputType: 'deploymentPolicy',
      valueType: 'long',
      vuexOptionKey: 'deploymentPolicyOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [
        Constants.PATCH_REMOTE_DEPLOYMENT,
        Constants.PACKAGES_REMOTE_DEPLOYMENT,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('configuration')} ${__tc('type')}`,
      paramName: 'action',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      options: dropdownTypeMap.configurationTypeOptions().options,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.PACKAGES_REMOTE_DEPLOYMENT],
    },
    {
      key: generateId(),
      name: __tc('task_type'),
      paramName: 'origin',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.taskTypeOptions().options,
      allowedModules: [Constants.PATCH_REMOTE_DEPLOYMENT],
    },
    {
      key: generateId(),
      name: `${__tc('start_date')}`,
      paramName: 'startTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.TASK],
    },
    {
      key: generateId(),
      name: `${__tc('end_date')}`,
      paramName: 'endTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.TASK],
    },
    {
      key: generateId(),
      name: __tc('created_time'),
      paramName: 'createdTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.date],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
        Constants.PURCHASE,
        Constants.USER_SURVEY,
        Constants.PROJECT,
        Constants.PATCH_REMOTE_DEPLOYMENT,
        Constants.PACKAGES_REMOTE_DEPLOYMENT,
        Constants.REGISTRY_REMOTE_DEPLOYMENT,
      ],
    },
    {
      key: generateId(),
      name: __tc('created_time'),
      paramName: 'createdTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.KNOWLEDGE],
    },
    {
      key: generateId(),
      name: __tc('required_by'),
      paramName: 'requiredByTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PURCHASE],
    },
    {
      key: generateId(),
      name: __tc('ordered_date'),
      paramName: 'orderedDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PURCHASE],
    },
    {
      key: generateId(),
      name: __tc('received_date'),
      paramName: 'receivedDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PURCHASE],
    },
    {
      key: generateId(),
      name: `${__tc('closed')} ${__tc('date')}`,
      paramName: 'purchaseCloseDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PURCHASE],
    },
    {
      key: generateId(),
      name: __tc('assignment_date'),
      paramName: 'assignmentTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_HARDWARE, Constants.ASSET_NON_IT],
    },
    {
      key: generateId(),
      name: `${__t('cost_center')}`,
      paramName: 'costCenter',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'costCenterOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.PURCHASE],
    },
    {
      key: generateId(),
      name: `${__t('gl_code')}`,
      paramName: 'glCode',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'glCodeOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.PURCHASE],
    },
    {
      key: generateId(),
      name: `${__tc('invoice')} ${__tc('received')}`,
      paramName: 'invoiceReceived',
      inputType: 'dropdown',
      valueType: 'enum',
      options: dropdownTypeMap.invoiceStatus().options,
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.PURCHASE],
    },
    {
      key: generateId(),
      name: `${__tc('requester')} ${__tc('number')}`,
      paramName: 'requesterNumber',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [
        Constants.CO_OPS_CALL_HISTORY,
        Constants.CO_OPS_TEAM_ACTIVITY_SUMMARY,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('call')} ${__tc('type')}`,
      paramName: 'callType',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      options: dropdownTypeMap.callTypeOptions().options,
      operators: [...operatorMaps.boolean],
      allowedModules: [Constants.CO_OPS_CALL_HISTORY],
    },
    {
      key: generateId(),
      name: `${__tc('call')} ${__tc('duration')}`,
      paramName: 'duration',
      inputType: 'number',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.CO_OPS_CALL_HISTORY],
    },
    {
      key: generateId(),
      name: `${__tc('payment')} ${__tc('status')}`,
      paramName: 'paymentStatus',
      inputType: 'dropdown',
      valueType: 'enum',
      options: dropdownTypeMap.paymentStatus().options,
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.PURCHASE],
    },
    {
      key: generateId(),
      name: __tc('acquisition_date'),
      paramName: 'acquitionTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
      ],
    },
    {
      key: generateId(),
      name: __tc('total_cost'),
      paramName: 'totalCost',
      inputType: 'number',
      valueType: 'double',
      allowDecimal: true,
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PURCHASE],
    },
    {
      key: generateId(),
      name: `${__tc('total')} ${__tc('invoice')} ${__tc('amount')}`,
      paramName: 'total_invoice_amount',
      inputType: 'number',
      valueType: 'double',
      paramType: 'variable',
      allowDecimal: true,
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PURCHASE],
    },

    {
      key: generateId(),
      name: `${__tc('total')} ${__tc('payment')} ${__tc('amount')}`,
      paramName: 'total_payment_amount',
      inputType: 'number',
      valueType: 'double',
      paramType: 'variable',
      allowDecimal: true,
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PURCHASE],
    },
    {
      key: generateId(),
      name: __tc('first_scan_date'),
      paramName: 'firstDiscoveredTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __tc('last_scan_date'),
      paramName: 'lastDiscoveredTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_HARDWARE],
    },
    {
      key: generateId(),
      name: __tc('last_updated_date'),
      paramName: 'updatedTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [
        Constants.REQUEST,
        Constants.KNOWLEDGE,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
        Constants.PURCHASE,
        Constants.USER_SURVEY,
        Constants.PROJECT,
        Constants.ASSET_MOVEMENT,
      ],
    },
    {
      key: generateId(),
      name: __tc('closed_date'),
      paramName: 'closedDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.ASSET_MOVEMENT],
    },
    {
      key: generateId(),
      name: __tc('last_resolved_time'),
      paramName: 'lastResolvedTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      showOnSupportPortal: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.REQUEST, Constants.PROBLEM],
    },
    {
      key: generateId(),
      name: __tc('last_closed_time'),
      paramName: 'lastClosedTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.USER_SURVEY,
      ],
    },
    {
      key: generateId(),
      name: __tc('closed_date'),
      paramName: 'closeDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PROJECT],
    },
    {
      key: generateId(),
      name: __tc('vip'),
      paramName: 'vip',
      inputType: 'boolean',
      valueType: 'boolean',
      exclusive: true,
      operators: [...operatorMaps.boolean],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: `${__tc('task_type')}`,
      paramName: 'taskTypeId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'taskTypeOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.TASK],
    },
    {
      key: generateId(),
      type: 'FlotoSubQueryRest',
      name: `${__tc('reference')}`,
      paramName: 'subQuery|task|name',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text_without_unary_operator],
      allowedModules: [Constants.TASK],
      moduleNameSelection: true,
    },
    {
      key: generateId(),
      name: `${__tc('created_by')}`,
      paramName: 'createdById',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      options: dropdownTypeMap.assignee().options,
      exclusive: true,
      operators: [...operatorMaps.listWithEqual],
      allowedModules: [Constants.TASK, Constants.KNOWLEDGE],
    },
    // for software asset
    {
      key: generateId(),
      name: `${__tc('software')} ${__tc('type')}`,
      paramName: 'swTypeId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'softwareTypeOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.ASSET_SOFTWARE],
    },
    // @TODO change paramname for intalltion notification
    // {
    //   key: generateId(),
    //   name: `${__tc('installation')} ${__tc('notification')}`,
    //   paramName: 'swComplianceAlert',
    //   inputType: 'boolean',
    //   valueType: 'boolean',
    //   exclusive: true,
    //   operators: [...operatorMaps.boolean],
    //   allowedModules: [Constants.ASSET_SOFTWARE],
    // },
    {
      key: generateId(),
      name: `${__tc('contract')} ${__tc('status')}`,
      paramName: 'contractStatus',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: [
        {
          text: __tc('not_started'),
          key: 'not_started',
        },
        {
          text: __tc('active'),
          key: 'active',
        },
        {
          text: __tc('expired'),
          key: 'expired',
        },
        {
          text: __tc('cancelled'),
          key: 'cancelled',
        },
      ],
      allowedModules: [Constants.CONTRACT],
    },
    {
      key: generateId(),
      name: `${__tc('status')}`,
      paramName: 'projectStatus',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      operators: [...operatorMaps.list],
      options: projectOptions,
      allowedModules: [Constants.PROJECT],
    },
    {
      key: generateId(),
      name: `${__tc('status')}`,
      paramName: 'status',
      inputType: 'dropdown',
      valueType: 'enum',
      exclusive: true,
      paramType: 'db',
      operators: [...operatorMaps.list],
      options: dropdownTypeMap.baselineStatus().options,
      allowedModules: [Constants.BASELINE],
    },
    {
      key: generateId(),
      name: `${__tc('contract')} ${__tc('number')}`,
      paramName: 'contractNumber',
      inputType: 'string',
      valueType: 'string',
      exclusive: true,
      operators: [...operatorMaps.text],
      allowedModules: [Constants.CONTRACT],
    },
    {
      key: generateId(),
      name: `${__tc('contract')} ${__tc('type')}`,
      paramName: 'contractTypeId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'contractTypeOptions',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.CONTRACT],
    },
    {
      key: generateId(),
      name: `${__tc('start_date')}`,
      paramName: 'surveyStartDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.USER_SURVEY],
    },
    {
      key: generateId(),
      name: `${__tc('planning')} ${__tc('start_date')}`,
      paramName: 'planningStartDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PROJECT],
    },
    {
      key: generateId(),
      name: `${__tc('implementation')} ${__tc('start_date')}`,
      paramName: 'implementationStartDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.PROJECT],
    },
    {
      key: generateId(),
      name: `${__tc('end_date')}`,
      paramName: 'surveyEndDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.USER_SURVEY],
    },
    {
      key: generateId(),
      name: `${__tc('contract')} ${__tc('start_date')}`,
      paramName: 'activationStartTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.CONTRACT],
    },
    {
      key: generateId(),
      name: `${__tc('contract')} ${__tc('end_date')}`,
      paramName: 'activationEndTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.CONTRACT],
    },
    {
      key: generateId(),
      name: `${__tc('contract')} ${__tc('renewal_date')}`,
      paramName: 'renewalDate',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [Constants.CONTRACT],
    },
    {
      key: generateId(),
      name: __t('owner'),
      paramName: 'technicianId',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      options: dropdownTypeMap.assignee().options,
      exclusive: true,
      operators: [...operatorMaps.listWithEqual],
      allowedModules: [
        Constants.CONTRACT,
        Constants.PURCHASE,
        Constants.PROJECT,
      ],
    },
    {
      key: generateId(),
      name: __t('owner'),
      paramName: 'owner',
      inputType: 'dropdown',
      valueType: 'long',
      vuexOptionKey: 'technicianOptions',
      options: dropdownTypeMap.assignee().options,
      exclusive: true,
      operators: [...operatorMaps.listWithEqual],
      allowedModules: [Constants.USER_SURVEY],
    },
    {
      key: generateId(),
      name: `${__tc('call')} ${__tc('time')}`,
      paramName: 'callTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date_without_greater_than],
      allowedModules: [Constants.CO_OPS_CALL_HISTORY],
    },
    {
      key: generateId(),
      name: __tc('install_after'),
      paramName: 'installAfterTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [
        Constants.PATCH_REMOTE_DEPLOYMENT,
        Constants.PACKAGES_REMOTE_DEPLOYMENT,
        Constants.REGISTRY_REMOTE_DEPLOYMENT,
      ],
    },
    {
      key: generateId(),
      name: __tc('expiry_date'),
      paramName: 'expireTime',
      inputType: 'createdTime',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.date],
      allowedModules: [
        Constants.PATCH_REMOTE_DEPLOYMENT,
        Constants.PACKAGES_REMOTE_DEPLOYMENT,
        Constants.REGISTRY_REMOTE_DEPLOYMENT,
      ],
    },
    {
      key: generateId(),
      name: `${__tc('closed')} ${__tc('by')}`,
      paramName: 'closedBy',
      inputType: 'requester',
      valueType: 'long',
      exclusive: true,
      vuexOptionKey: 'requesterOptions',
      operators: [...operatorMaps.list],
      allowedModules: [Constants.REQUEST],
    },
    {
      key: generateId(),
      name: `${__tc('resolved')} ${__tc('by')}`,
      paramName: 'resolvedBy',
      inputType: 'requester',
      valueType: 'long',
      exclusive: true,
      vuexOptionKey: 'requesterOptions',
      operators: [...operatorMaps.list],
      allowedModules: [Constants.REQUEST],
    },
    {
      name: `${__tc('reboot')} ${__tc('required')}`,
      paramName: 'rebootBehaviour',
      inputType: 'dropdown',
      valueType: 'enum',
      options: dropdownTypeMap.rebootBehaviour().options,
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.PATCH],
    },
    {
      key: generateId(),
      name: `${__tc('reboot')} ${__tc('required')}`,
      paramName: 'rebootRequired',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: dropdownTypeMap.booleanOptions().options,
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.PATCH_COMPUTERS],
    },
    {
      key: generateId(),
      name: `${__tc('allow_delegate_approval_team')}`,
      paramName: 'allowDelegateApprovalTeam',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: dropdownTypeMap.booleanOptions().options,
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: `${__tc('allow_delegate_approval')}`,
      paramName: 'allowDelegateApproval',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: dropdownTypeMap.booleanOptions().options,
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: `${__tc('allow_work_delegation_for_self')}`,
      paramName: 'allowWorkDelegationForSelf',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: dropdownTypeMap.booleanOptions().options,
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: `${__tc('allow_work_delegation_for_team')}`,
      paramName: 'allowWorkDelegationForTeam',
      inputType: 'dropdown',
      valueType: 'boolean',
      options: dropdownTypeMap.booleanOptions().options,
      exclusive: true,
      operators: [...operatorMaps.equal],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: __tc('identity_provider'),
      paramName: 'ssoConfigId',
      vuexOptionKey: 'ssoConfigOptions',
      inputType: 'ssoConfig',
      valueType: 'long',
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.USER],
    },
    {
      key: generateId(),
      name: `${__tc('available')} ${__tc('quantity')}`,
      paramName: 'available_quantity',
      paramType: 'variable',
      inputType: 'number',
      valueType: 'long',
      allowDecimal: false,
      exclusive: true,
      operators: [...operatorMaps.number],
      allowedModules: [Constants.ASSET_CONSUMABLE],
    },
    {
      key: generateId(),
      name: `${__tc('service_category')} `,
      paramName: 'service_category',
      paramType: 'variable',
      inputType: 'dropdown',
      vuexOptionKey: 'serviceCategories',
      valueType: 'long',
      allowDecimal: false,
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.REQUEST],
    },
    {
      key: generateId(),
      name: `${__tc('service_catalog')}`,
      paramName: 'serviceCatalogId',
      inputType: 'service_catalog',
      vuexOptionKey: 'serviceCatalogOptions',
      valueType: 'long',
      allowDecimal: false,
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.REQUEST],
    },
    {
      key: generateId(),
      name: `${__tc('service')} ${__tc('custom_field')}`,
      paramName: 'serviceCustomField',
      inputType: 'service_custom_field',
      valueType: 'long',
      advancedSearchParam: true,
      allowDecimal: false,
      exclusive: true,
      operators: [...operatorMaps.list],
      allowedModules: [Constants.REQUEST],
    },
  ]

  const filteredSources = allSources.filter((s) =>
    (s.allowedModules || []).includes(moduleName)
  )
  let customFieldSources = []
  if (resourceFields) {
    SortBy(
      flattenFields(resourceFields || []).filter(
        (f) => !f.isSystemField && excludedField.indexOf(f.type) === -1
      ),
      'order'
    ).map((field) => {
      customFieldSources.push(transformCustomFieldForSearch(field))
    })
  }
  let availableSources = filteredSources.concat(customFieldSources)
  if (onlyCustomFields) {
    availableSources = customFieldSources
  }
  if (supportData.length) {
    availableSources = availableSources.map((source) => {
      const sourceParamName = source.paramName
      const supportDataSource = supportData.find((qualification) => {
        const list = qualification.value.split('.')
        const supportedDataparamName = list[list.length - 1]
        return supportedDataparamName === sourceParamName
      })
      if (supportDataSource) {
        const fieldDetailsOptions = (
          supportDataSource.fieldDetailsOptions || []
        ).map((i) => ({ ...i, singleValueSelection: true }))
        return {
          ...source,
          fieldDetailsOptions,
          operators: [
            ...(source.operators || []),
            ...operatorMaps.same_as_not_same_as,
          ],
        }
      } else {
        return source
      }
    })
    return availableSources
  } else {
    return availableSources
  }
}

export function transformCustomFieldForSearch(field) {
  return {
    key: generateId(),
    name: field.title,
    paramName: String(field.id),
    inputType:
      buildInputTypeForField(field) === 'date'
        ? 'createdDate'
        : buildInputTypeForField(field),
    valueType: buildValueTypeForField(field),
    exclusive: true,
    operators:
      field.fieldResolutionType === 'system_field'
        ? buildOperatorsForField(field).filter(
            (o) => unaryOperators.indexOf(o.key) === -1
          )
        : buildOperatorsForField(field),
    ...getAdditionalFieldProps(field),
    customFieldType: field.type,
    ...(field.fieldResolutionType === 'system_field' &&
    field.fieldReferenceType &&
    field.fieldReferenceType in customSystemFileMappingMap
      ? {
          ...customSystemFileMappingMap[field.fieldReferenceType],
          fieldReferenceType: field.fieldReferenceType,
        }
      : {}),
  }
}
