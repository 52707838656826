<template>
  <component
    :is="selectionComponent"
    ref="list"
    as-table
    :scrollable="searchable || scrollable"
    :module-name="moduleName"
    :immediate="!isEnableSearchSelector || !searchable"
    :fetch-fn="fetchTicketFn"
    :update-fn="updateTicketFn"
    :columns="tableColumns"
    :selectable="selectable"
    :max-allowed-selection="maxAllowedSelection"
    :default-selected-item-ids="defaultSelectedItemIds"
    v-bind="$attrs"
    default-sort="createdTime"
    @selection-change="$emit('selection-change', $event)"
  >
    <template v-if="searchable" v-slot:add-controls="{ refreshList }">
      <slot name="search-selector">
        <MRow class="items-center">
          <MCol>
            <h2 class="font-normal inline-block mb-0">
              {{ $tc(moduleName, 2) }}
            </h2>
            <FlotoSearchSelector
              v-model="currentSelectedSearch"
              :class="{ invisible: hideSearchSelector }"
              exclude-archived-spam-qual
              :allow-pin="false"
              :persist-search="false"
              :module-name="moduleName"
            />
          </MCol>
        </MRow>
      </slot>
      <MRow class="my-4">
        <MCol
          :class="{
            invisible:
              ((currentSelectedSearch || {}).systemName || '').indexOf(
                'spam'
              ) >= 0 ||
              ((currentSelectedSearch || {}).systemName || '').indexOf(
                'archive'
              ) >= 0,
          }"
        >
          <FlotoSearchBar
            :allow-save-search="false"
            :persist-criteria="false"
            :module-name="moduleName"
            v-bind="{
              ...searchBarProps,
              ...(isEnableSearchSelector
                ? {
                    defaultValue: currentSelectedSearch
                      ? currentSelectedSearch.qualifications
                      : [],
                  }
                : {}),
              ...(isEnableSearchSelector ? { value: searchCriteria } : {}),
              ...(isEnableSearchSelector
                ? { shouldNotifyOnDefaultChange: true }
                : {}),
            }"
            @change="applyCriteria($event, refreshList)"
          />
        </MCol>
      </MRow>
    </template>
    <template v-if="allowBulkAction" v-slot:bulk-actions="slotData">
      <BulkActions
        v-bind="slotData"
        :resource-id="resourceId"
        :module-name="moduleName"
        mode="license_installation"
        @refresh="$emit('refresh')"
      />
    </template>
    <template v-slot:form>
      <span />
    </template>
    <template v-slot:displayName="{ item }">
      <td>
        <ResourceListTitle
          :name="item.name"
          :subject="item.displayName"
          :created-at="0"
          open-in-new-tab
          :link="getLink(item)"
          :internal-link="linkable"
        />
      </td>
    </template>
    <template
      v-if="hiddenColumns.indexOf('assetTypeId') < 0"
      v-slot:assetTypeId="{ item }"
    >
      <td>
        <div class="flex">
          <AssetTypeIcon
            :asset-type-id="item.assetTypeId"
            size="md"
            class="mr-2"
          />
          <FlotoAssetTypePicker :value="item.assetTypeId" disabled />
        </div>
      </td>
    </template>
    <template v-slot:statusId="{ item }">
      <td>
        <FlotoStatusPicker
          :module-name="$constants.ASSET"
          placeholder="---"
          :value="item.statusId"
          disabled
        />
      </td>
    </template>
    <template v-slot:impactId="{ item }">
      <td>
        <FlotoImpactPicker :value="item.impactId" disabled placeholder="---" />
      </td>
    </template>
    <template v-slot:ipAddress="{ item }">
      <td>
        {{ item.ipAddress || '---' }}
      </td>
    </template>
    <template v-slot:hostName="{ item }">
      <td>
        {{ item.hostName || '---' }}
      </td>
    </template>
    <template v-slot:managedById="{ item }">
      <td>
        <FlotoTechnicianPicker
          :value="item.managedById"
          :group-id="item.managedByGroupId"
          disabled
        />
      </td>
    </template>
    <template v-slot:ignoreVersion="{ item, update }">
      <td align="center">
        <MSwitch
          :checked="item.ignoreVersion"
          size="small"
          class="mr-2"
          @change="update(item)"
        />
      </td>
    </template>
    <template v-slot:assignedUserIds="{ item }">
      <td>
        <FlotoRequesterPicker
          multiple
          as-tag
          :value="item.assignedUserIds || undefined"
          disabled
          placeholder="---"
          dropdown-size="small"
        />
      </td>
    </template>
    <template v-slot:managedByGroupId="{ item }">
      <td>
        <FlotoTechnicianGroupPicker :value="item.managedByGroupId" disabled />
      </td>
    </template>
    <template v-slot:version="{ item }">
      <td>
        {{ item.version || '---' }}
      </td>
    </template>
    <template v-slot:swTypeId="{ item }">
      <td>
        <SoftwareTypePicker
          :value="item.swTypeId"
          placeholder="---"
          as-input
          size="small"
          disabled
        />
      </td>
    </template>

    <template v-slot:swCategoryId="{ item }">
      <td>
        <FlotoCategoryPicker
          :value="item.swCategoryId"
          :module-name="$constants.ASSET_SOFTWARE"
          class="w-full"
          placeholder="---"
          size="small"
          disabled
        />
      </td>
    </template>
    <template
      v-if="hiddenColumns.indexOf('lastDiscoveredTime') < 0"
      v-slot:lastDiscoveredTime="{ item }"
    >
      <td>
        <span v-if="item.lastDiscoveredTime">
          {{ item.lastDiscoveredTime | datetime }}
        </span>
        <span v-else>---</span>
      </td>
    </template>
    <template v-slot:barcodeAndQrcode="{ item }">
      <td>
        <span v-if="item.barCode || tenantPrefrences.qrCodeSupport">
          <BarcodeQrcodeRenderer :asset="item" :module-name="item.model" />
        </span>
        <span v-else>---</span>
      </td>
    </template>
    <template v-slot:actions="{ item, remove }">
      <td
        :class="{
          'sticky-action': actionSticked.indexOf(item.id) >= 0,
          'hover-action-container': hoverableActions,
        }"
      >
        <slot
          name="action"
          :item="item"
          :stick-action="() => stickActionFor(item)"
          :unstick-action="() => unStickActionFor(item)"
        >
          <div class="flex justify-left items-center"> </div>
          <FlotoDeleteBtn
            :disabled="disabled"
            :message="
              $t('confirm_delete_item', {
                item: `${$tc('asset')}`,
              })
            "
            @confirm="remove"
          />
        </slot>
      </td>
    </template>
    <template v-slot="slotData">
      <slot v-bind="slotData"></slot>
    </template>
  </component>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import Uniq from 'lodash/uniq'
import AssetTypeIcon from '@components/custom-icon/asset-type-icon'
import {
  transformSearchCriteriasForServer,
  transformSearchCriteriaForServer,
} from '@data/search'
import { searchAssetsApi } from '@modules/asset/asset-api'
import ResourceListTitle from '@components/resource/resource-list-title'
import SoftwareTypePicker from '@components/data-picker/software-type-picker'
import PaginatedCrud from '@components/paginated-crud'
import BulkActions from './bulk-action/bulk-actions'
import BarcodeQrcodeRenderer from '@modules/asset/components/barcode-qrcode-renderer'
import { SupportPortalConfigComputed } from '@state/modules/support-portal-config'
import { PreferenceComputed } from '@state/modules/preference'

export default {
  name: 'AssetSelectionList',
  components: {
    ResourceListTitle,
    SoftwareTypePicker,
    AssetTypeIcon,
    PaginatedCrud,
    BulkActions,
    BarcodeQrcodeRenderer,
  },
  props: {
    moduleName: { type: String, required: true },
    resourceId: { type: Number, default: undefined },
    fetchFn: { type: Function, default: undefined },
    updateFn: { type: Function, default: undefined },
    hasActions: { type: Boolean, default: false },
    showBarcodeAndQrcode: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    excludeArchivedSpamQual: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    hideSearchSelector: { type: Boolean, default: false },
    showIgnoreVersion: { type: Boolean, default: false },
    allowBulkAction: { type: Boolean, default: false },
    hiddenColumns: {
      type: Array,
      default() {
        return []
      },
    },
    excludeItemsById: {
      type: Array,
      default() {
        return []
      },
    },
    ids: {
      type: Array,
      default: undefined,
    },
    hoverableActions: { type: Boolean, default: false },
    // eslint-disable-next-line
    linkable: { type: Boolean, default: true },
    defaultSelectedItemIds: {
      type: Array,
      default() {
        return []
      },
    },
    maxAllowedSelection: { type: Number, default: 0 },
    clientSidePagination: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    searchBarProps: {
      type: Object,
      default() {
        return {}
      },
    },
    sortable: { type: Boolean, default: false },
  },
  data() {
    return {
      searchCriteria: transformSearchCriteriasForServer(this.moduleName, []),
      actionSticked: [],
      currentSelectedSearch: null,
      tableColumns: [],
    }
  },
  computed: {
    ...SupportPortalConfigComputed,
    ...PreferenceComputed,
    isEnableSearchSelector() {
      return !this.$slots['search-selector']
    },
    selectionComponent() {
      if (this.clientSidePagination) {
        return 'PaginatedCrud'
      }
      return 'FlotoCrudContainer'
    },
  },
  created() {
    this.$nextTick(() => {
      this.tableColumns = this.generateTableColumns()
    })
  },
  methods: {
    applyCriteria(searchCriteria, refreshList) {
      this.searchCriteria = searchCriteria
      refreshList()
    },
    updateTicketFn(item) {
      return this.updateFn(item).then(() => {
        this.refresh()
      })
    },
    generateTableColumns() {
      let columns = [
        {
          name: `${this.$t('name')}`,
          key: 'displayName',
          minWidth: '220px',
          widthClass: 'xl:w-6/12 lg:w-5/12 md:w-4/12',
          sortable: this.sortable,
        },
        {
          name: `${this.$tc('asset_type')}`,
          key: 'assetTypeId',
          minWidth: '160px',
        },
        ...(this.hiddenColumns.indexOf('statusId') < 0
          ? [
              {
                name: `${this.$t('status')}`,
                key: 'statusId',
                minWidth: '160px',
                sortable: this.sortable,
              },
            ]
          : []),
        ...(this.hiddenColumns.indexOf('impactId') < 0 &&
        [
          this.$constants.ASSET_NON_IT,
          this.$constants.ASSET_CONSUMABLE,
        ].indexOf(this.moduleName) >= 0
          ? [
              {
                name: `${this.$t('impact')}`,
                key: 'impactId',
                minWidth: '160px',
              },
            ]
          : []),
        ...(this.hiddenColumns.indexOf('version') < 0 &&
        this.moduleName === this.$constants.ASSET_SOFTWARE
          ? [
              {
                name: `${this.$tc('version')}`,
                key: 'version',
                minWidth: '160px',
              },
            ]
          : []),
        ...(this.hiddenColumns.indexOf('swTypeId') < 0 &&
        this.moduleName === this.$constants.ASSET_SOFTWARE
          ? [
              {
                name: `${this.$tc('software')} ${this.$tc('type')}`,
                key: 'swTypeId',
                minWidth: '160px',
                sortable: this.sortable,
              },
            ]
          : []),

        // ...(this.hiddenColumns.indexOf('swCategoryId') < 0 &&
        // this.moduleName === this.$constants.ASSET_SOFTWARE
        //   ? [
        //       {
        //         name: `${this.$tc('software')} ${this.$tc('category')}`,
        //         key: 'swCategoryId',
        //         minWidth: '160px',
        //       },
        //     ]
        //   : []),
        ...(this.hiddenColumns.indexOf('hostName') < 0 &&
        [this.$constants.ASSET_HARDWARE, this.$constants.ASSET].indexOf(
          this.moduleName
        ) >= 0
          ? [
              {
                name: this.$t('host_name'),
                key: 'hostName',
                minWidth: '160px',
              },
            ]
          : []),
        ...(this.hiddenColumns.indexOf('ipAddress') < 0 &&
        [this.$constants.ASSET_HARDWARE, this.$constants.ASSET].indexOf(
          this.moduleName
        ) >= 0
          ? [
              {
                name: this.$t('ip_address'),
                key: 'ipAddress',
                minWidth: '160px',
              },
            ]
          : []),
        ...(this.hiddenColumns.indexOf('assignedUserIds') < 0 &&
        [
          this.$constants.ASSET_HARDWARE,
          this.$constants.ASSET_NON_IT,
          this.$constants.ASSET_CONSUMABLE,
          this.$constants.ASSET,
        ].indexOf(this.moduleName) >= 0
          ? [
              {
                name: `${this.$t('used_by')}`,
                key: 'assignedUserIds',
                minWidth: '160px',
              },
            ]
          : []),
        ...(this.hiddenColumns.indexOf('managedByGroupId') < 0 &&
        [
          this.$constants.ASSET_HARDWARE,
          this.$constants.ASSET_SOFTWARE,
          this.$constants.ASSET_NON_IT,
          this.$constants.ASSET_CONSUMABLE,
        ].indexOf(this.moduleName) >= 0
          ? [
              {
                name: `${this.$tc('managed_by')} ${this.$tc('group')}`,
                key: 'managedByGroupId',
                minWidth: '160px',
              },
            ]
          : []),
        ...(this.hiddenColumns.indexOf('managedById') < 0 &&
        [
          this.$constants.ASSET_HARDWARE,
          this.$constants.ASSET_SOFTWARE,
          this.$constants.ASSET_NON_IT,
          this.$constants.ASSET_CONSUMABLE,
        ].indexOf(this.moduleName) >= 0
          ? [
              {
                name: `${this.$t('managed_by')}`,
                key: 'managedById',
                minWidth: '160px',
              },
            ]
          : []),
        ...(this.showIgnoreVersion
          ? [
              {
                name: `${this.$tc('ignore_version')}`,
                key: 'ignoreVersion',
              },
            ]
          : []),
        ...(this.showBarcodeAndQrcode &&
        this.allowRequesterToViewBarcodeAndQRCode
          ? [
              {
                name: this.$tc('barcode_and_qr_code'),
                key: 'barcodeAndQrcode',
                minWidth: '80px',
              },
            ]
          : []),
        // ...(this.hiddenColumns.indexOf('lastDiscoveredTime') < 0 &&
        // [
        //   this.$constants.ASSET_HARDWARE,
        //   this.$constants.ASSET_SOFTWARE,
        //   this.$constants.ASSET_NON_IT,
        // ].indexOf(this.moduleName) >= 0
        //   ? [
        //       {
        //         name: this.$t('last_discovered_time'),
        //         key: 'lastDiscoveredTime',
        //         minWidth: '160px',
        //       },
        //     ]
        //   : []),
        ...(this.hasActions
          ? [
              {
                name: this.$tc('action', 2),
                key: 'actions',
                minWidth: '80px',
              },
            ]
          : []),
      ]
      return columns
    },
    getLink(item) {
      let link
      if (String(item.id).indexOf(':') >= 0) {
        link = this.$modules.getModuleRoute('asset', 'view', {
          params: {
            id: String(item.id).split(':')[0],
            assetType: String(item.id).split(':')[1],
          },
        })
      } else {
        link = this.$modules.getModuleRoute('asset', 'view', {
          params: { id: item.id, assetType: this.moduleName },
        })
      }
      return link
    },
    fetchTicketFn(limit, offset, sortedColumn) {
      const criterias = CloneDeep(this.searchCriteria)
      if (this.excludeItemsById.length) {
        const q = transformSearchCriteriaForServer(this.moduleName, {
          operator: { value: 'not_in' },
          paramName: 'id',
          value: this.excludeItemsById,
          valueType: 'long',
        })
        criterias.quals.push(q)
      }

      if (this.ids) {
        const q = transformSearchCriteriaForServer(this.moduleName, {
          operator: { value: 'in' },
          paramName: 'id',
          value: this.ids,
          valueType: 'long',
        })
        criterias.quals.push(q)
      }

      if (this.fetchFn) {
        return this.fetchFn(
          this.moduleName,
          criterias,
          limit,
          offset,
          sortedColumn
        )
      } else {
        return searchAssetsApi(this.moduleName, criterias, limit, offset)
      }
    },
    refresh() {
      this.$refs.list.refresh()
    },
    stickActionFor(item) {
      this.actionSticked = Uniq([...this.actionSticked, item.id])
    },
    unStickActionFor(item) {
      this.actionSticked = this.actionSticked.filter((id) => id !== item.id)
    },
  },
}
</script>
