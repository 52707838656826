<template>
  <FlotoContentLoader :loading="taskLoading" :height="600">
    <component :is="useFormHelpterProvider ? 'FormHelperProvider' : 'div'">
      <FormConsumer
        ref="formRef"
        :form-fields="allCustomFormFields"
        :module-name="$constants.TASK"
        :value="value"
        :with-submit="withSubmit"
        :avoid-default-value="avoidDefaultValue"
        :apply-form-rules="
          formRulesSupportedModules.indexOf($constants.TASK) >= 0
        "
        :form-rules="formRules"
        :defalut-task-type-system-name="$route.query.taskType"
        @change="$emit('change', $event)"
        @reset-form="$emit('reset-form', $event)"
        @submit="handleFormSubmitted"
      >
        <template v-slot:submit>
          <span />
        </template>
        <template v-slot:reset>
          <span />
        </template>
      </FormConsumer>
    </component>
  </FlotoContentLoader>
</template>

<script>
import SortBy from 'lodash/sortBy'
import { FormComputed } from '@state/modules/form'
import FormConsumer from '@components/form-consumer.vue'
import FormHelperProvider from '@components/form-helper/form-helper-provider.vue'
import { PreferenceComputed } from '@state/modules/preference'
import { getFormRulesApi } from '@modules/form-rules/form-rules-api'
import { formRulesSupportedModules } from '@data/form-rules'

export default {
  name: 'TaskForm',
  components: { FormConsumer, FormHelperProvider },
  model: {
    event: 'change',
  },
  props: {
    useEstimatedTime: { type: Boolean, default: false },
    useTaskDuration: { type: Boolean, default: false },
    useTaskCompletion: { type: Boolean, default: false },
    // eslint-disable-next-line
    useStatus: { type: Boolean, default: true },
    // eslint-disable-next-line
    useStartTime: { type: Boolean, default: true },
    // eslint-disable-next-line
    useEndTime: { type: Boolean, default: true },
    // eslint-disable-next-line
    useFormHelpterProvider: { type: Boolean, default: true },
    moduleName: {
      type: String,
      default: undefined,
    },
    withSubmit: { type: Boolean, required: true },
    avoidDefaultValue: { type: Boolean, default: false },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    exclude: {
      type: Array,
      default() {
        return []
      },
    },
    additionalCustomFormFields: {
      type: Array,
      default() {
        return []
      },
    },
    resetForm: { type: Function, required: true },
  },
  data() {
    this.formRulesSupportedModules = formRulesSupportedModules
    return {
      formRules: [],
      formRulesLoading: true,
    }
  },
  computed: {
    ...PreferenceComputed,
    ...FormComputed,
    globalLoading() {
      return this.taskLoading || this.formRulesLoading
    },
    customFormFields() {
      let allFields = this.taskFields({
        exclude: [
          ...(this.useEstimatedTime ? [] : ['estimated_time']),
          ...(this.useTaskDuration ? [] : ['task_duration']),
          ...(this.useTaskCompletion ? [] : ['task_completion']),
          ...(this.useStatus ? [] : ['status']),
          ...(this.useStartTime ? [] : ['start_time']),
          ...(this.useEndTime ? [] : ['end_time']),
        ],
      })
      return allFields
    },
    allCustomFormFields() {
      if (this.additionalCustomFormFields.length) {
        return SortBy(
          [...this.customFormFields, ...this.additionalCustomFormFields],
          'order'
        )
      }
      return this.customFormFields
    },
  },
  created() {
    this.getFormRules()
  },
  methods: {
    getFormRules() {
      return getFormRulesApi({
        moduleName: this.$constants.TASK,
        name: '',
      }).then((data) => {
        this.formRules = data.items.filter((i) => i.enabled)
        this.formRulesLoading = false
      })
    },
    handleFormSubmitted(data) {
      this.$emit('submit', {
        ...data,
      })
    },
    submit() {
      this.$refs.formRef.submit()
    },
  },
}
</script>
